import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Col, Button, Row } from 'react-bootstrap';

const EquipmentBar = ({ title, className }) => {
  const { id } = useParams();
  return (
    <Card className={className}>
      <Card.Body>
        <Row className="flex-between-center">
          {title && (
            <Col md>
              <h5 className="mb-2 mb-md-0">{title}</h5>
            </Col>
          )}
          <Col xs="auto">
            <Button size="sm" variant="falcon-primary" type="submit">
              {id ? 'Zapisz' : 'Opublikuj'}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EquipmentBar.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default EquipmentBar;

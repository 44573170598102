import React from 'react';
import PropTypes from 'prop-types';
import TooltipBadge from 'components/common/TooltipBadge';
import { Placeholder } from 'react-bootstrap';
import { Button, Col, Row } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import ProfileBanner from '../ProfileBanner';
import { Link } from 'react-router-dom';
import RoleBadge from 'components/common/RoleBadge';
import Flex from 'components/common/Flex';

const Banner = ({ profile }) => {
  const BannerAvatar = () => {
    return !profile ? (
      <div style={{ width: 100, height: 100 }}>
        <Placeholder as="div" className="w-100 h-100 " animation="glow">
          <span className="placeholder w-100 h-100 rounded-circle" />
        </Placeholder>
      </div>
    ) : (
      <Avatar
        size="4xl"
        name={profile.avatar.name}
        className="mb-2"
        mediaClass="img-thumbnail shadow-sm"
      />
    );
  };

  const BannerContent = () => {
    return !profile ? (
      <>
        <Placeholder as="h4" className="w-25 mb-1" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as="h5" className="w-50 fs-0" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as="p" className="w-25" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as="p" className="w-25 mb-1" animation="glow">
          <Placeholder xs={4} />
          <Placeholder xs={4} className="ms-2" />
        </Placeholder>
      </>
    ) : (
      <>
        <h4 className="mb-1">
          {profile.firstName} {profile.lastName}
          {profile.inTraining && (
            <TooltipBadge
              tooltip="Okres próbny"
              icon="star"
              color="secondary"
            />
          )}
        </h4>
        <h5 className="fs-0 fw-normal">{profile.position}</h5>
        <Flex alignItems="center mb-2" gap={2}>
          <RoleBadge role={profile.role} as="span" />
          <p className="text-500 mb-0">{profile.address}</p>
        </Flex>
        {profile.role === 'service' && (
          <Flex alignItems="center mt-2" gap={2}>
            <Button
              variant="falcon-primary"
              size="sm"
              className="px-3"
              as={Link}
              to={`/create-task?userID=${profile._id}&userName=${profile.firstName} ${profile.lastName}`}
            >
              Przydziel zadanie
            </Button>
            <Button
              variant="falcon-default"
              size="sm"
              className="px-3 ms-2"
              disabled
            >
              Wiadomość
            </Button>
          </Flex>
        )}
      </>
    );
  };

  return (
    <ProfileBanner>
      <ProfileBanner.Body>
        <Row>
          <Col lg="auto">
            <BannerAvatar />
          </Col>
          <Col lg={8} className="d-flex justify-content-center flex-column">
            <BannerContent />
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

Banner.propTypes = {
  profile: PropTypes.object
};

export default Banner;

import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BasicECharts from 'components/common/BasicEChart';

import * as echarts from 'echarts';
import { getColor } from 'helpers/utils';
import FalconLink from 'components/common/FalconLink';
import SimpleBarReact from 'simplebar-react';
import { getTotalWorkTime } from 'helpers/getTotalWorkTime';

const MembersRow = ({
  _id,
  firstName,
  lastName,
  avatar,
  role,
  isLast,
  status,
  today,
  thisWeek,
  tasks
}) => {
  return (
    <tr className={classNames({ 'border-bottom border-200': !isLast })}>
      <td className="align-middle">
        <Flex alignItems="center" className="position-relative">
          {avatar.img ? (
            <Avatar
              className={`status-${status}`}
              size="xl"
              src={avatar.img}
              width="60"
              alt={firstName + ' ' + lastName}
            />
          ) : (
            <Avatar
              size="xl"
              name={avatar.name}
              className={`status-${status}`}
            />
          )}
          <div className="flex-1 ms-3">
            <h6 className="mb-0 fw-semi-bold">
              <Link className="text-dark stretched-link" to={`/user/${_id}`}>
                {firstName} {lastName}
              </Link>
            </h6>
            <p className="fs--2 mb-0 text-500">{role}</p>
          </div>
        </Flex>
      </td>
      <td className="align-middle fw-semi-bold">
        <div className="flex-1">
          {tasks?.length > 0 ? (
            tasks.map(task => (
              <Link
                key={task._id}
                className="text-dark"
                to={`/task/${task._id}`}
              >
                <p className="fs--2 mb-0 text-500">{task.name}</p>
              </Link>
            ))
          ) : (
            <p className="fs--2 mb-0 text-500">Brak zadań</p>
          )}
        </div>
      </td>
      <td className="align-middle text-center fw-semi-bold">
        {/* 
        todo: confirm if needed
        <SoftBadge pill bg={today.color}>
          {today.amount}%
        </SoftBadge> */}
        <p className="fs--2 mb-0">{getTotalWorkTime([today])}</p>
      </td>
      <td className="align-middle">
        <Row className="g-2 justify-content-end">
          <Col xs="auto">
            {/* 
            todo: confirm if needed
            <SoftBadge pill bg={thisWeek.color}>
              {thisWeek.amount}%
            </SoftBadge> */}
            <p className="fs--2 mb-0">{getTotalWorkTime([thisWeek])}</p>
          </Col>
          <Col xs="auto" className="mt-auto">
            <BasicECharts
              echarts={echarts}
              options={{
                color: getColor('primary'),
                tooltip: { show: false },
                series: [
                  {
                    data: thisWeek.data
                  }
                ]
              }}
              className="mb-1"
              style={{ width: '3.625rem', height: '1rem' }}
            />
          </Col>
        </Row>
      </td>
    </tr>
  );
};

const UserInfo = ({ data }) => {
  return (
    <Card className="h-100">
      <Card.Body className="p-0">
        <SimpleBarReact>
          <Table
            borderless
            className="mb-0 fs--1 border-200 rounded-3 table-dashboard table-member-info"
          >
            <thead className="bg-light">
              <tr className="text-900">
                <th>Status użytkowników</th>
                <th className="text">Aktualne zadania</th>
                <th className="text-center">Dzisiejsze statystyki</th>
                <th className="text-end">Tygodniowe statystyki</th>
              </tr>
            </thead>
            <tbody>
              {data.map((info, index) => (
                <MembersRow
                  {...info}
                  isLast={index === data.length - 1}
                  key={info.id}
                />
              ))}
            </tbody>
          </Table>
        </SimpleBarReact>
      </Card.Body>

      <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto"></Col>
          <Col xs="auto">
            <FalconLink
              to="/user-list"
              title="Wszyscy użytkownicy"
              className="px-0"
            />
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

MembersRow.propTypes = {
  _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
  avatar: PropTypes.shape({
    img: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  tasks: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  today: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired
  }),
  thisWeek: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired,
    data: PropTypes.array
  })
};

UserInfo.propTypes = {
  data: PropTypes.array.isRequired
};

export default UserInfo;

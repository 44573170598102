export default [
  {
    id: 0,
    userId: 1,
    type: 'user',
    messagesId: 0,
    read: true
  },
  {
    id: 1,
    userId: 23,
    type: 'user',
    messagesId: 2,
    read: true
  }
];

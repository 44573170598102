import React from 'react';
import PropTypes from 'prop-types';
import ProfileInfo from './ProfileInfo';
import ProfileLog from './ProfileLog';

const ProfileDetails = ({ profile }) => {
  return (
    <>
      <ProfileInfo profile={profile} />
      <ProfileLog profile={profile} />
    </>
  );
};

ProfileDetails.propTypes = {
  profile: PropTypes.object
};

export default ProfileDetails;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import cloudDownload from 'assets/img/icons/cloud-download.svg';
import iconDocs from 'assets/img/icons/docs.png';

import useFormatDate from 'hooks/useFormatDate';
import useTranslation from 'hooks/useTranslation';
import axios from 'axios';
import { toast } from 'react-toastify';

const SharedFiles = ({ files: initialFiles, className, parent }) => {
  const [files, setFiles] = useState(initialFiles);

  const handleRemoveFile = fileId => {
    setFiles(prevFiles => prevFiles.filter(file => file._id !== fileId));
  };

  return (
    <Card className={className}>
      <FalconCardHeader title="Dodane pliki" light />
      <Card.Body className="pb-0">
        {files.map((file, index) => (
          <SharedFile
            key={index}
            file={file}
            isLast={index === files.length - 1}
            parent={parent}
            onRemove={handleRemoveFile}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

const SharedFile = ({ file, isLast, parent, onRemove }) => {
  const { _id: fileId, url, name, type, createdBy, createdAt, border } = file;
  const { _id: parentId, type: parentRoute } = parent;
  const [isLoading, setIsLoading] = useState(false);
  const [formattedDate] = useFormatDate(createdAt, false);
  const filePath = `${process.env.REACT_APP_FILE_HOST}${url}`;

  const handleRemove = async () => {
    setIsLoading(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/${parentRoute}/${parentId}/files/${fileId}`
      );
      toast.success(useTranslation(response.data.message, 'pl'), {
        theme: 'colored'
      });

      onRemove(fileId);
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Flex
        alignItems="center"
        className={classNames('mb-3 hover-actions-trigger', {
          'pe-none opacity-25': isLoading
        })}
      >
        <div className="file-thumbnail">
          <img
            className={classNames('h-100 w-100 fit-cover rounded-2', {
              border: border
            })}
            src={type === 'application/pdf' ? iconDocs : filePath}
            alt=""
          />
        </div>
        <div className="ms-3 flex-shrink-1 flex-grow-1">
          <h6 className="mb-1">
            <span className="text-900 fw-semi-bold">{name}</span>
          </h6>
          <div className="fs--1">
            <span className="fw-semi-bold">
              {createdBy.firstName} {createdBy.lastName}
            </span>
            <span className="fw-medium text-600 ms-2">{formattedDate}</span>
          </div>
          <div className="hover-actions end-0 top-50 translate-middle-y">
            <Button
              variant="light"
              size="sm"
              className="border-300 me-1 text-600"
              as={'a'}
              href={filePath}
              download
            >
              <img src={cloudDownload} alt="Download" width={15} />
            </Button>
            <Button
              variant="light"
              size="sm"
              className="border-300 text-600"
              onClick={handleRemove}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
          </div>
        </div>
      </Flex>
      {!isLast && <hr className="text-200" />}
    </>
  );
};

SharedFile.propTypes = {
  file: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    createdBy: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    border: PropTypes.bool
  }),
  isLast: PropTypes.bool,
  parent: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  onRemove: PropTypes.func
};

SharedFiles.propTypes = {
  files: PropTypes.arrayOf(SharedFile.propTypes.file),
  className: PropTypes.string,
  parent: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  })
};

export default SharedFiles;

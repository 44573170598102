import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ProfileBanner from './Banner';
import Tasks from './Tasks';
import ProfileDetails from './profile-details/ProfileDetails';
import ProfileGmap from './profile-details/ProfileGmap';
import { toast } from 'react-toastify';

const UserProfile = () => {
  const { userId } = useParams();

  const [profile, setProfile] = useState(null);
  const [userPositions, setUserPositions] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/${userId}`
        );
        const profileData = response.data.user;
        const tasks = await axios.get(
          `${process.env.REACT_APP_API_URL}/tasks?users=${userId}&status[nin]=archived`
        );
        profileData.tasks = tasks.data.tasks;
        setProfile(profileData);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    const fetchUserPositions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/userPositions?userId=${userId}`
        );
        const userPositionsData = response.data.userPositions;
        setUserPositions(userPositionsData);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchUserProfile();
    fetchUserPositions();
  }, [userId]);

  return (
    <>
      <ProfileBanner profile={profile} />
      <ProfileDetails profile={profile} />
      <Tasks
        className="mb-3"
        cardTitle="Aktualne zadania"
        tasks={profile?.tasks}
      />
      <ProfileGmap userPositions={userPositions} />
    </>
  );
};

export default UserProfile;

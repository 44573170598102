import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Placeholder, Row } from 'react-bootstrap';

import Task from 'components/app/tasks/task-list/Task';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';

const Tasks = ({ cardTitle, tasks, ...rest }) => {
  const TaskContent = () => {
    return tasks === undefined ? (
      <Col>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
      </Col>
    ) : tasks?.length > 0 ? (
      tasks.map(task => (
        <Col xs={6} key={task._id}>
          <Task details={task} isLast={task === tasks.length - 1} />
        </Col>
      ))
    ) : (
      <Col>
        <p>Brak aktualnych zadań dla tego użytkownika</p>
      </Col>
    );
  };

  return (
    <Card {...rest}>
      <FalconCardHeader title={cardTitle} light />
      <Card.Body className="fs--1 border-bottom">
        <Row>
          <TaskContent />
        </Row>
      </Card.Body>
      <FalconCardFooterLink
        title="Wszystkie zadania"
        to="/task-list"
        size="sm"
      />
    </Card>
  );
};

Tasks.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape(Event.propTypes))
};

export default Tasks;

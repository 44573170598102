import React from 'react';
import { PropTypes } from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EquipmentDetailGmap from './EquipmentDetailGmap';
import Divider from 'components/common/Divider';

const EquipmentDetailContent = ({
  equipment: {
    catalogNumber,
    serialNumber,
    inventoryNumber,
    description,
    categories,
    location
  }
}) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <h5 className="fs-0 mb-3">Szczegóły urządzenia</h5>
        <p>
          Nr katalogowy: <strong>{catalogNumber}</strong>
        </p>
        <p>
          Nr seryjny: <strong>{serialNumber}</strong>
        </p>
        <p>
          Nr inwentarzowy: <strong>{inventoryNumber}</strong>
        </p>
        <Divider />
        <p>{description}</p>
        {categories.length > 0 && (
          <>
            <h5 className="fs-0 mt-5 mb-2 ">Kategorie</h5>
            {categories.map(category => (
              <Link
                key={category._id}
                to="#!"
                className="badge border link-secondary text-decoration-none me-1"
              >
                {category.name}
              </Link>
            ))}
          </>
        )}
        <EquipmentDetailGmap center={location.coordinates} />
      </Card.Body>
    </Card>
  );
};

EquipmentDetailContent.propTypes = {
  equipment: PropTypes.object
};

export default EquipmentDetailContent;

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import AppContext from 'context/Context';
import googleMapStyles from 'helpers/googleMapStyles';
import {
  GoogleMap as ReactGoogleMap,
  Polyline,
  useJsApiLoader
} from '@react-google-maps/api';

const ProfileGmap = ({ userPositions }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });

  const {
    config: { isDark }
  } = useContext(AppContext);

  const [mapStyles, setMapStyles] = useState('Default');
  const [initialCenter, setInitialCenter] = useState({
    lat: 52.22977,
    lng: 21.01178
  });

  useEffect(() => {
    if (userPositions && userPositions.length > 0) {
      const totalLat = userPositions.reduce((acc, curr) => acc + curr.lat, 0);
      const totalLon = userPositions.reduce((acc, curr) => acc + curr.lon, 0);

      const avgLat = totalLat / userPositions.length;
      const avgLon = totalLon / userPositions.length;

      setInitialCenter({ lat: avgLat, lng: avgLon });
    }
  }, [userPositions]);

  const mapOptions = {
    mapTypeControl: true,
    streetViewControl: false,
    fullscreenControl: true,
    styles: googleMapStyles[mapStyles]
  };

  useEffect(() => {
    if (isDark) setMapStyles('Cobalt');
    else setMapStyles('Default');
  }, [isDark]);

  if (!isLoaded) return <div className="my-3">Wczytywanie mapy...</div>;

  return (
    <Card className="mb-3">
      <Card.Body>
        <div style={{ height: '400px' }}>
          <ReactGoogleMap
            zoom={11}
            options={mapOptions}
            center={initialCenter}
            mapContainerStyle={{
              width: '100%',
              height: '100%'
            }}
          >
            {userPositions && userPositions.length > 0 && (
              <Polyline
                path={userPositions.map(position => ({
                  lat: position.lat,
                  lng: position.lon
                }))}
                options={{
                  strokeColor: '#F00',
                  strokeOpacity: 1,
                  strokeWeight: 6
                }}
              />
            )}
          </ReactGoogleMap>
        </div>
      </Card.Body>
    </Card>
  );
};

ProfileGmap.propTypes = {
  userPositions: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired
    })
  )
};

export default ProfileGmap;

import React, { useState, useEffect, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Activity } from 'components/dashboards/project-management/RecentActivity';

const ProfileLog = () => {
  const [logs, setLogs] = useState([]);
  const [visibleLogs, setVisibleLogs] = useState(5);
  const { userId } = useParams();

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/userActions/?user=${userId}&limit=${visibleLogs}`
        );
        setLogs(response.data.userActions);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLogs();

    const intervalId = setInterval(() => {
      fetchLogs();
    }, 120000); // 2 minutes

    return () => clearInterval(intervalId);
  }, [userId]);

  const visibleLogsMemo = useMemo(
    () => logs.slice(0, visibleLogs),
    [logs, visibleLogs]
  );

  const handleLoadMore = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/userActions/?user=${userId}&limit=${
          visibleLogs + 5
        }`
      );
      setLogs(response.data.userActions);
      setVisibleLogs(visibleLogs + 5);
    } catch (error) {
      console.error(error);
      toast.error('Nie udało się załadować więcej aktywności');
    }
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0">Ostatnie aktualizacje</h5>
      </Card.Header>
      <Card.Body className="border-top ps-2">
        {visibleLogsMemo.map((log, index) => (
          <Activity
            key={index}
            activity={log}
            isLast={index === visibleLogsMemo.length - 1}
          />
        ))}
      </Card.Body>
      {visibleLogsMemo.length < logs.length && (
        <Card.Footer className="bg-light p-0">
          <IconButton
            variant="link"
            iconClassName="fs--2 ms-1"
            icon="chevron-right"
            className="d-block w-100"
            iconAlign="right"
            onClick={handleLoadMore}
          >
            Pokaż więcej
          </IconButton>
        </Card.Footer>
      )}
    </Card>
  );
};

export default ProfileLog;

import React, { Fragment, useState, useContext } from 'react';
import axios from 'axios';
import { taskPropTypes } from 'shapes/taskPropTypes';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Button,
  Placeholder,
  Form,
  Modal
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import AppContext from 'context/Context';

const TaskDetailHeader = ({ task }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { _id, name, users, equipment } = task;
  const navigate = useNavigate();
  const [isOpenUpdatesModal, setOpenUpdatesModal] = useState(false);
  const [updateContent, setUpdateContent] = useState('');

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      'Jesteś pewien że chcesz usunąć to zadanie? Tej operacji nie można cofnąć. Czy chcesz kontynuować?'
    );
    if (confirmDelete) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/tasks/${_id}`
        );
        navigate('/task-list');
        toast.success(useTranslation(response.data.message, 'pl'), {
          theme: 'colored'
        });
      } catch (error) {
        console.error(error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      }
    }
  };

  const handleClose = () => {
    setOpenUpdatesModal(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/tasks/${_id}/update`,
        { updateNote: updateContent }
      );
      handleClose();
      setUpdateContent('');
      toast.success(useTranslation(response.data.message, 'pl'), {
        theme: 'colored'
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleChange = event => {
    setUpdateContent(event.target.value);
  };

  if (!task) {
    return (
      <Card className="mb-3">
        <Card.Body className="bg-light border-top">
          <Placeholder as={Row} animation="glow" />
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="p-0 mb-3">
        <Card.Body className="overflow-hidden">
          <Row className="flex-center">
            <Col>
              <Flex>
                <div className="fs--1 ms-2 flex-1">
                  <h3 className="fs-1 text-capitalize">{name}</h3>
                  <p className="mb-0">
                    Przypisane do:
                    {users.map((user, index) => (
                      <Fragment key={index}>
                        <Link to={`/user/${user._id}/`} className="ps-1">
                          {user.firstName} {user.lastName}
                        </Link>
                        {index !== users.length - 1 && ', '}
                      </Fragment>
                    ))}
                  </p>
                  <span className="text-warning fw-semi-bold">
                    Urządzenie:
                    <Link
                      to={`/equipment/${equipment._id}`}
                      className="text-warning ps-1"
                    >
                      {equipment.equipmentId} / {equipment.name}
                    </Link>
                  </span>
                </div>
              </Flex>
            </Col>
            <Col md="auto" className="mt-4 mt-md-0">
              <Flex>
                <Button
                  variant="falcon-info"
                  size="sm"
                  className="me-2"
                  onClick={() => {
                    setOpenUpdatesModal(true);
                  }}
                >
                  Dodaj aktualizację
                </Button>
                <Button
                  as={Link}
                  to={`/create-task/${_id}`}
                  className="me-2"
                  variant="falcon-default"
                  size="sm"
                  icon="share-alt"
                >
                  Edytuj
                </Button>
                <Button
                  variant="falcon-danger"
                  size="sm"
                  className="px-4 px-sm-5"
                  onClick={handleDelete}
                >
                  Usuń
                </Button>
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={isOpenUpdatesModal} onHide={handleClose}>
        <Form>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-x1 border-bottom-0"
          >
            <Modal.Title as="h5">Dodaj aktualizację</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-x1">
            <Form.Group className="mb-3" controlId="updateInput">
              <Form.Label className="fs-0">Treść</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                name="content"
                required
                value={updateContent}
                onChange={handleChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-x1 border-top-0">
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="px-4 mx-0"
            >
              Dodaj
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

TaskDetailHeader.propTypes = {
  task: taskPropTypes.isRequired
};

export default TaskDetailHeader;

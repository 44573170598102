import iconDocs from 'assets/img/icons/docs.png';
import placeholder from 'assets/img/placeholders/placeholder.png';

const currentYear = new Date().getUTCFullYear();
let currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');

export default [
  {
    id: 102,
    timeZone: 'GMT-12:00/Etc/GMT-12',
    selectType: '3',
    selectTopic: '1',
    startDate: `${currentYear}-${currentMonth}-01 10:00:00`,
    startTime: '2023-02-26T07:45:00.172Z',
    endDate: `${currentYear}-${currentMonth}-03 16:00:00`,
    endTime: '2023-02-26T07:45:00.651Z',
    location: 'Miasto',
    address: 'Bachorzew, ul. Nowy Świat 15',
    center: {
      lat: 51.97266,
      lng: 17.50256
    },
    city: 'Jarocin',
    zip: '63-200',
    country: 'Polska',
    title: 'Commodo Dolor Pellentesque Nibh',
    description:
      'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla. Curabitur blandit tempus porttitor. Etiam porta sem malesuada magna mollis euismod.\n\nNulla vitae elit libero, a pharetra augue. Vestibulum id ligula porta felis euismod semper. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Etiam porta sem malesuada magna mollis euismod.',
    uploadedFiles: [
      {
        id: 1,
        img: placeholder,
        name: 'Rysunek techniczny.png',
        user: 'Mateusz Cierzniak',
        time: 'Przed chwilą',
        border: true
      },
      {
        id: 5,
        img: iconDocs,
        name: 'instrukcja.pdf',
        user: 'Mateusz Cierzniak',
        time: '27/11/22 o 10:30',
        border: false
      }
    ],
    uploadedImages: [],
    privacy: 'publicPage',
    taskNotification: true,
    equipment: {
      id: 'C0009',
      label: 'Ciągnik siodłowy'
    },
    users: [
      {
        id: 1,
        name: 'Jan Kowalski'
      },
      {
        id: 2,
        name: 'Anna Nowak'
      }
    ],
    categories: [
      {
        id: 2,
        label: 'Kategoria 2'
      }
    ],
    status: 'pending',
    badge: {
      title: 'Nowe',
      type: 'success'
    }
  },
  {
    id: 103,
    timeZone: 'GMT-12:00/Etc/GMT-12',
    selectType: '3',
    selectTopic: '1',
    startDate: `${currentYear}-${currentMonth}-03 10:00:00`,
    startTime: '2023-02-26T11:45:00.172Z',
    endDate: `${currentYear}-${currentMonth}-07 16:00:00`,
    endTime: '2023-02-26T12:45:00.651Z',
    location: 'Miasto',
    address: 'Bachorzew, ul. Nowy Świat 15',
    center: {
      lat: 51.97266,
      lng: 17.50256
    },
    city: 'Jarocin',
    zip: '63-200',
    country: 'Polska',
    title: 'Lorem Sem Sit Fusce',
    description:
      'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla. Curabitur blandit tempus porttitor. Etiam porta sem malesuada magna mollis euismod.\n\nNulla vitae elit libero, a pharetra augue. Vestibulum id ligula porta felis euismod semper. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Etiam porta sem malesuada magna mollis euismod.',
    uploadedFiles: [
      {
        id: 1,
        img: placeholder,
        name: 'Rysunek techniczny.png',
        user: 'Mateusz Cierzniak',
        time: 'Przed chwilą',
        border: true
      },
      {
        id: 5,
        img: iconDocs,
        name: 'instrukcja.pdf',
        user: 'Mateusz Cierzniak',
        time: '27/11/22 o 10:30',
        border: false
      }
    ],
    uploadedImages: [],
    privacy: 'publicPage',
    taskNotification: true,
    equipment: {
      id: 'C0009',
      label: 'Ciągnik siodłowy'
    },
    users: [
      {
        id: 3,
        name: 'Piotr Wiśniewski'
      }
    ],
    categories: [
      {
        id: 2,
        label: 'Kategoria 2'
      }
    ],
    status: 'inprogress',
    badge: {
      title: 'Nowe',
      type: 'success'
    }
  },
  {
    id: 104,
    timeZone: 'GMT-12:00/Etc/GMT-12',
    selectType: '3',
    selectTopic: '1',
    startDate: `${currentYear}-${currentMonth}-21`,
    startTime: '2023-02-26T07:45:00.172Z',
    endDate: `${currentYear}-${currentMonth}-21`,
    endTime: '2023-02-26T10:45:00.651Z',
    location: 'Miasto',
    address: 'Bachorzew, ul. Nowy Świat 15',
    center: {
      lat: 51.97266,
      lng: 17.50256
    },
    city: 'Jarocin',
    zip: '63-200',
    country: 'Polska',
    title: 'Lorem Sem Sit Fusce',
    description:
      'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla. Curabitur blandit tempus porttitor. Etiam porta sem malesuada magna mollis euismod.\n\nNulla vitae elit libero, a pharetra augue. Vestibulum id ligula porta felis euismod semper. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Etiam porta sem malesuada magna mollis euismod.',
    uploadedFiles: [
      {
        id: 1,
        img: placeholder,
        name: 'Rysunek techniczny.png',
        user: 'Mateusz Cierzniak',
        time: 'Przed chwilą',
        border: true
      },
      {
        id: 5,
        img: iconDocs,
        name: 'instrukcja.pdf',
        user: 'Mateusz Cierzniak',
        time: '27/11/22 o 10:30',
        border: false
      }
    ],
    uploadedImages: [],
    privacy: 'publicPage',
    taskNotification: true,
    equipment: {
      id: 'K0035',
      label: 'Koparko-Ładowarka'
    },
    users: [
      {
        id: 4,
        name: 'Magdalena Kowalczyk'
      },
      {
        id: 3,
        name: 'Piotr Wiśniewski'
      }
    ],
    categories: [
      {
        id: 2,
        label: 'Kategoria 2'
      }
    ],
    status: 'draft',
    badge: {
      title: 'Nowe',
      type: 'success'
    }
  },
  {
    id: 105,
    timeZone: 'GMT-12:00/Etc/GMT-12',
    selectType: '3',
    selectTopic: '1',
    startDate: `${currentYear}-${currentMonth}-13 10:00:00`,
    startTime: '2023-02-26T07:45:00.172Z',
    endDate: `${currentYear}-${currentMonth}-15 16:00:00`,
    endTime: '2023-02-26T07:45:00.651Z',
    location: 'Miasto',
    address: 'Bachorzew, ul. Nowy Świat 15',
    center: {
      lat: 51.97266,
      lng: 17.50256
    },
    city: 'Jarocin',
    zip: '63-200',
    country: 'Polska',
    title: 'Vestibulum Justo Sem',
    description:
      'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla. Curabitur blandit tempus porttitor. Etiam porta sem malesuada magna mollis euismod.\n\nNulla vitae elit libero, a pharetra augue. Vestibulum id ligula porta felis euismod semper. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Etiam porta sem malesuada magna mollis euismod.',
    uploadedFiles: [
      {
        id: 1,
        img: placeholder,
        name: 'Rysunek techniczny.png',
        user: 'Mateusz Cierzniak',
        time: 'Przed chwilą',
        border: true
      },
      {
        id: 5,
        img: iconDocs,
        name: 'instrukcja.pdf',
        user: 'Mateusz Cierzniak',
        time: '27/11/22 o 10:30',
        border: false
      }
    ],
    uploadedImages: [],
    privacy: 'publicPage',
    taskNotification: true,
    equipment: {
      id: 'C0009',
      label: 'Ciągnik siodłowy'
    },
    users: [
      {
        id: 1,
        name: 'Jan Kowalski'
      },
      {
        id: 2,
        name: 'Anna Nowak'
      }
    ],
    categories: [
      {
        id: 2,
        label: 'Kategoria 2'
      }
    ],
    status: 'pending',
    badge: {
      title: 'Nowe',
      type: 'success'
    }
  }
];

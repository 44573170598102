import { Collapse, Form, Col, Row, Button } from 'react-bootstrap';
import CustomDateInput from 'components/common/CustomDateInput';
import PropTypes from 'prop-types';
import React from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import axios from 'axios';
import PriorityBadge from 'components/common/PriorityBadge';
import StatusBadge from 'components/common/StatusBadge';
import DatePicker from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import { useBreakpoints } from 'hooks/useBreakpoints';

export const TaskFilters = ({ open, setQueryParams }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      status: [],
      priority: []
    }
  });
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null
  });
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const { breakpoints } = useBreakpoints();
  const isMobile = breakpoints.down('md');

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const fetchData = async () => {
    try {
      // Fetch equipment options
      const equipmentResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment/`
      );
      const equipmentOptionsData = equipmentResponse.data.equipment.map(
        ({ _id, equipmentId, name }) => ({
          value: _id,
          label: `${equipmentId} | ${name}`
        })
      );
      setEquipmentOptions(equipmentOptionsData);

      // Fetch user options
      const usersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/users?role=service`
      );
      const userOptionsData = usersResponse.data.users.map(
        ({ _id, firstName, lastName }) => ({
          value: _id,
          label: `${firstName} ${lastName}`
        })
      );
      setUserOptions(userOptionsData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const onSubmit = data => {
    let queryParts = [];

    if (data.name) {
      queryParts.push(`q=${encodeURIComponent(data.name)}`);
    }

    if (data.equipment?.length > 0) {
      const equipmentIds = data.equipment.map(item => item.value);
      equipmentIds.map(id => queryParts.push(`equipment[in]=${id}`));
    }

    if (data.type?.length > 0) {
      const types = data.type.map(item => item.value);
      types.map(type => queryParts.push(`type[in]=${type}`));
    }

    if (data.users?.length > 0) {
      const users = data.users.map(item => item.value);
      users.map(user => queryParts.push(`users[in]=${user}`));
    }

    if (data.priority?.length > 0) {
      data.priority.map(priority =>
        queryParts.push(`priority[in]=${!isMobile ? priority : priority.value}`)
      );
    }

    if (data.status?.length > 0) {
      data.status.map(status =>
        queryParts.push(`status[in]=${!isMobile ? status : status.value}`)
      );
    }
    console.log(data.startDate);
    console.log(data.endDate);

    if (data.startDate) {
      queryParts.push(
        `startDate[gte]=${new Date(data.startDate).toISOString()}`
      );
    }

    if (data.endDate) {
      queryParts.push(`startDate[lte]=${new Date(data.endDate).toISOString()}`);
    }

    setQueryParams(queryParts?.join('&') || '');
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formValues = watch();

  return (
    <Collapse in={open}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="name" className="mb-3 d-flex flex-column">
              <Form.Label>Nazwa zadania</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nazwa zadania"
                isInvalid={!!errors.name}
                {...register('name')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group
              controlId="equipment"
              className="mb-3 d-flex flex-column"
            >
              <Flex className="flex-between-center">
                <Form.Label>Urządzenia</Form.Label>
              </Flex>
              <Controller
                name="equipment"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={true}
                    isMulti
                    isOptionDisabled={option =>
                      option.isDisabled || formValues.equipment?.length >= 5
                    }
                    options={equipmentOptions}
                    placeholder="Wybierz urządzenie..."
                    isInvalid={!!errors.equipment}
                    clearable
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="type" className="mb-3 d-flex flex-column">
              <Form.Label>Typ zadania</Form.Label>
              <Controller
                name="type"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={true}
                    isMulti
                    clearable
                    options={[
                      {
                        value: 'Naprawa',
                        label: 'Naprawa'
                      },
                      {
                        value: 'Obsługa techniczna',
                        label: 'Obsługa techniczna'
                      },
                      {
                        value: 'Kontrola',
                        label: 'Kontrola'
                      }
                    ]}
                    placeholder="Wybierz typ zadania..."
                    isInvalid={!!errors.type}
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="users" className="mb-3 d-flex flex-column">
              <Form.Label>Pracownik</Form.Label>
              <Controller
                name="users"
                render={({ ref, field }) => (
                  <MultiSelect
                    ref={ref}
                    {...field}
                    closeMenuOnSelect={false}
                    isMulti
                    isOptionDisabled={option =>
                      option.isDisabled || formValues.users?.length >= 5
                    }
                    clearable
                    options={userOptions}
                    placeholder="Wybierz pracownika..."
                    isInvalid={!!errors.users}
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group
              className="mb-3 d-flex flex-column"
              controlId="startDate"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Form.Label>Data rozpoczęcia</Form.Label>
              <DatePicker
                selected={formData.startDate}
                showTimeSelect
                timeFormat="HH:mm"
                locale={pl}
                timeIntervals={60}
                dateFormat="dd/MM/yyyy HH:mm"
                isClearable
                timeCaption="Czas:"
                onChange={newDate => {
                  if (formData.startDate === newDate) {
                    handleChange('startDate', '');
                    setValue('startDate', '');
                  } else {
                    handleChange('startDate', newDate);
                    setValue('startDate', newDate);
                  }
                }}
                customInput={
                  <CustomDateInput
                    isInvalid={!!errors.startDate}
                    errorMessage={errors.startDate?.message}
                    formControlProps={{
                      placeholder: 'd/m/y HH:mm',
                      name: 'startDate',
                      ...register('startDate')
                    }}
                  />
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group
              className="mb-3 d-flex flex-column"
              controlId="endDate"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Form.Label>Data zakończenia</Form.Label>
              <DatePicker
                selected={formData.endDate}
                showTimeSelect
                timeFormat="HH:mm"
                locale={pl}
                timeIntervals={60}
                dateFormat="dd/MM/yyyy HH:mm"
                isClearable
                timeCaption="Czas:"
                onChange={newDate => {
                  if (formData.endDate === newDate) {
                    handleChange('endDate', '');
                    setValue('endDate', '');
                  } else {
                    handleChange('endDate', newDate);
                    setValue('endDate', newDate);
                  }
                }}
                customInput={
                  <CustomDateInput
                    isInvalid={!!errors.endDate}
                    errorMessage={errors.endDate?.message}
                    formControlProps={{
                      placeholder: 'd/m/y HH:mm',
                      name: 'endDate',
                      ...register('endDate')
                    }}
                  />
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={3} lg={2}>
            <Form.Label>Priorytet</Form.Label>
            <Form.Group className="mb-3 d-flex flex-column">
              {!isMobile ? (
                <Flex
                  alignItems="center"
                  justifyContent="start"
                  style={{ columnGap: '10px' }}
                >
                  <Form.Check className="mb-0 px-0" id="priorityLow">
                    <Form.Check.Input
                      type="checkbox"
                      value="9"
                      className="btn-check"
                      {...register(`priority`)}
                    />
                    <PriorityBadge priority="9" />
                  </Form.Check>
                  <Form.Check className="mb-0 px-0" id="priorityMedium">
                    <Form.Check.Input
                      type="checkbox"
                      value="5"
                      className="btn-check"
                      {...register(`priority`)}
                    />
                    <PriorityBadge priority="5" />
                  </Form.Check>
                  <Form.Check className="mb-0 px-0" id="priorityHigh">
                    <Form.Check.Input
                      type="checkbox"
                      value="0"
                      className="btn-check"
                      style={{ width: 0, height: 0 }}
                      {...register(`priority`)}
                    />
                    <PriorityBadge priority="0" />
                  </Form.Check>
                </Flex>
              ) : (
                <Controller
                  name="priority"
                  render={({ ref, field }) => (
                    <MultiSelect
                      {...field}
                      ref={ref}
                      closeMenuOnSelect={true}
                      isMulti
                      clearable
                      options={[
                        {
                          value: '9',
                          label: 'Niski'
                        },
                        {
                          value: '5',
                          label: 'Średni'
                        },
                        {
                          value: '0',
                          label: 'Wysoki'
                        }
                      ]}
                      placeholder="Wybierz priorytet..."
                      isInvalid={!!errors.type}
                    />
                  )}
                  control={control}
                />
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4} xl={3}>
            <Form.Label>Status</Form.Label>
            <Form.Group className="mb-3 d-flex flex-column">
              {!isMobile ? (
                <Flex
                  alignItems="center"
                  justifyContent="start"
                  wrap="wrap"
                  style={{ gap: '6px' }}
                >
                  <Form.Check className="mb-0 px-0" id="statusDraft">
                    <Form.Check.Input
                      type="checkbox"
                      value="draft"
                      className="btn-check"
                      {...register(`status`)}
                    />
                    <Form.Check.Label className="mb-0">
                      <StatusBadge
                        checked={
                          !!formValues?.status?.find(item => item === 'draft')
                        }
                        status="draft"
                      />
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-0 px-0" id="statusPending">
                    <Form.Check.Input
                      type="checkbox"
                      value="pending"
                      className="btn-check"
                      {...register(`status`)}
                    />
                    <Form.Check.Label className="mb-0">
                      <StatusBadge
                        checked={
                          !!formValues?.status?.find(item => item === 'pending')
                        }
                        status="pending"
                      />
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-0 px-0" id="statusArchived">
                    <Form.Check.Input
                      type="checkbox"
                      value="archived"
                      className="btn-check"
                      {...register(`status`)}
                    />
                    <Form.Check.Label className="mb-0">
                      <StatusBadge
                        checked={
                          !!formValues?.status?.find(
                            item => item === 'archived'
                          )
                        }
                        status="archived"
                      />
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-0 px-0" id="statusOnhold">
                    <Form.Check.Input
                      type="checkbox"
                      value="onhold"
                      className="btn-check"
                      {...register(`status`)}
                    />
                    <Form.Check.Label className="mb-0">
                      <StatusBadge
                        checked={
                          !!formValues?.status?.find(item => item === 'onhold')
                        }
                        status="onhold"
                      />
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-0 px-0" id="statusActive">
                    <Form.Check.Input
                      type="checkbox"
                      value="active"
                      className="btn-check"
                      {...register(`status`)}
                    />
                    <Form.Check.Label className="mb-0">
                      <StatusBadge
                        checked={
                          !!formValues?.status?.find(item => item === 'active')
                        }
                        status="active"
                      />
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-0 px-0" id="statusCompleted">
                    <Form.Check.Input
                      type="checkbox"
                      value="completed"
                      className="btn-check"
                      {...register(`status`)}
                    />
                    <Form.Check.Label className="mb-0">
                      <StatusBadge
                        checked={
                          !!formValues?.status?.find(
                            item => item === 'completed'
                          )
                        }
                        status="completed"
                      />
                    </Form.Check.Label>
                  </Form.Check>
                </Flex>
              ) : (
                <Controller
                  name="status"
                  render={({ ref, field }) => (
                    <MultiSelect
                      {...field}
                      ref={ref}
                      closeMenuOnSelect={true}
                      isMulti
                      clearable
                      options={[
                        {
                          value: 'draft',
                          label: 'Szkic'
                        },
                        {
                          value: 'pending',
                          label: 'Do potwierdzenia'
                        },
                        {
                          value: 'archived',
                          label: 'Zarchiwizowane'
                        },
                        {
                          value: 'onhold',
                          label: 'Wstrzymane'
                        },
                        {
                          value: 'active',
                          label: 'W trakcie'
                        },
                        {
                          value: 'completed',
                          label: 'Zakończone'
                        }
                      ]}
                      placeholder="Wybierz status..."
                      isInvalid={!!errors.type}
                    />
                  )}
                  control={control}
                />
              )}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Flex
              justifyContent="end"
              alignItems="end"
              style={{
                height: '100%'
              }}
            >
              <Button
                size="sm"
                variant="falcon-primary"
                type="submit"
                transform="shrink-3"
              >
                <span>Zastosuj filtry</span>
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Collapse>
  );
};

TaskFilters.propTypes = {
  open: PropTypes.bool,
  setQueryParams: PropTypes.func
};

import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Greetings from './Greetings';
import CalendarManagement from './calendar/CalendarManagement';
import Statistics from './statistics/Statistics';
import UserInfo from './UserInfo';
import RunningTasks from './RunningTasks';
import ProjectLocation from './project-location/ProjectLocation';
import { greetingItems } from 'data/dashboard/projectManagement';
import RecentActivity from './RecentActivity';
import axios from 'axios';
import { toast } from 'react-toastify';

import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';

const ProjectManagement = () => {
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const markers = tasks.map(task => ({
    id: task._id,
    name: task.name,
    lat: task.location.coordinates[1],
    long: task.location.coordinates[0],
    street: task.location.formattedAddress
  }));

  let completedTasksCount = 0;
  for (let obj of tasks) {
    completedTasksCount += obj.tasks.filter(task => task.completed).length;
  }

  const statistics = [
    {
      title: 'Zrealizowane zadania',
      value: completedTasksCount,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '/task-list',
      linkText: 'Zobacz aktualne zadania',
      image: bg1
    },
    {
      title: 'Godziny prac urządzeń',
      value: 23.43,
      decimal: true,
      suffix: ' tys.',
      prefix: '',
      valueClassName: 'text-info',
      link: '/equipment-list',
      linkText: 'Lista urządzeń',
      image: bg2
    }
  ];

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tasks?status[ne]=archived`
        );
        setTasks(response.data.tasks);
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas pobierania zadań');
      }
    };
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users?role=service&status[ne]=archived&limit=5`
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas pobierania użytkowników');
      }
    };

    fetchTasks();
    fetchUsers();
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <Greetings data={greetingItems} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <Statistics statsData={statistics} />
        </Col>
        <Col xxl={8} lg={6}>
          <ProjectLocation data={markers} />
        </Col>
        <Col xxl={4} lg={6}>
          <RecentActivity />
        </Col>
        <Col xxl={12}>
          <UserInfo data={users} />
        </Col>
        <Col xxl={12}>
          <RunningTasks tasks={tasks.slice(0, 5)} />
        </Col>
      </Row>
      <Row className="g-3">
        <Col xxl={12}>
          <CalendarManagement />
        </Col>
      </Row>
    </>
  );
};

export default ProjectManagement;

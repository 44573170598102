import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import AcountDetails from './AccountDetails';
import AccountSettings from './AccountSettings';
import ChangePassword from './ChangePassword';
import DangerZone from './DangerZone';
import axios from 'axios';
import LoadingSpinner from 'components/common/LoadingSpinner';
import AuthContext from 'context/auth/AuthContext';
import PasswordResetLink from './PasswordResetLink';

const defaultValues = {
  role: 'service'
};

const CreateUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const { userId } = useParams();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    defaultValues
  });

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/${userId}`
      );
      const user = response.data.user;
      if (user) {
        Object.keys(user).forEach(key => {
          setValue(key, user[key]);
        });
        setUser(user);
      }
    } catch (error) {
      console.error('Failed to fetch user', error);
      toast.error('Błąd podczas pobierania użytkownika');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const onSubmit = async data => {
    setIsLoading(true);

    const userData = {
      ...data,
      avatar: {
        name: `${data.firstName} ${data.lastName}`
      }
    };

    if (userId) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/users/${userId}`,
          userData
        );
        toast.success(useTranslation(response.data.message, 'pl'), {
          theme: 'colored'
        });
      } catch (error) {
        console.error('Failed to create/update user', error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      } finally {
        setIsLoading(false);
        navigate('/user-list', { replace: true });
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/users/add`,
          userData
        );
        toast.success(useTranslation(response.data.message, 'pl'), {
          theme: 'colored'
        });
      } catch (error) {
        console.error('Failed to create/update user', error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      } finally {
        setIsLoading(false);
        navigate('/user-list', { replace: true });
      }
    }
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <div>
      <Row className="g-3">
        <Col lg={8}>
          <AcountDetails
            user={user}
            register={register}
            errors={errors}
            isLoading={isLoading}
            onSubmit={handleSubmit(onSubmit)}
          />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            {['admin', 'superadmin'].includes(authContext?.user?.role) && (
              <AccountSettings
                user={user}
                register={register}
                errors={errors}
                watch={watch}
              />
            )}
            {userId && (
              <>
                {authContext?.user?.id === userId && <ChangePassword />}
                {authContext?.user?.id !== userId && <PasswordResetLink />}
                {authContext?.user?.id !== userId && (
                  <DangerZone user={user} setUser={setUser} />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CreateUser;

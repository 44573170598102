import React from 'react';
import PropTypes from 'prop-types';

import Flex from './Flex';
import Spinner from 'react-bootstrap/Spinner';

const LoadingSpinner = ({ message = 'Przesyłanie danych...' }) => {
  return (
    <Flex
      className="loading-spinner"
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Spinner
        animation="border"
        variant="primary"
        role="status"
        className="align-self-center"
      />
      <p className="mt-3 fs--1">{message}</p>
    </Flex>
  );
};

LoadingSpinner.propTypes = {
  message: PropTypes.string
};

export default LoadingSpinner;

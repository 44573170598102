import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import StatisticsCard from './StatisticsCard';

const Statistics = ({ statsData }) => {
  return (
    <Row className="g-3">
      {statsData.map(stat => (
        <Col key={stat.title} sm={6}>
          <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
        </Col>
      ))}
    </Row>
  );
};

Statistics.propTypes = {
  statsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      decimal: PropTypes.bool,
      suffix: PropTypes.string,
      prefix: PropTypes.string,
      valueClassName: PropTypes.string,
      badgeBg: PropTypes.string,
      badgeText: PropTypes.string,
      link: PropTypes.string,
      linkText: PropTypes.string
    })
  ).isRequired
};

export default Statistics;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Button, Card } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const PasswordResetLink = ({ user }) => {
  const [status, setStatus] = useState('');

  const resetPassword = async () => {
    setStatus('loading');
    if (user) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/password/reset`,
          {
            email: user?.email
          }
        );
        toast.success('Wysłano wiadomość e-mail z linkiem do resetu hasła');
        setStatus('success');
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas wysyłania wiadomości e-mail');
      }
    }
  };

  if (status === 'success') {
    return null;
  }

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Resetuj hasło" />
      <Card.Body className="bg-light">
        <p className="fs--1">
          Wyślij link do resetu hasła na adres e-mail użytkownika.
        </p>
        <Button
          onClick={resetPassword}
          variant="falcon-default"
          className="w-100"
          disabled={status === 'loading'}
        >
          {status === 'loading' ? 'Wysyłanie...' : 'Wyślij link'}
        </Button>
      </Card.Body>
    </Card>
  );
};

PasswordResetLink.propTypes = {
  user: PropTypes.object
};

export default PasswordResetLink;

import React from 'react';
import LoginForm from 'components/authentication/LoginForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgVideo from 'assets/video/romgos_intro_final.mp4';
import Flex from 'components/common/Flex';

const Login = () => {
  return (
    <AuthSplitLayout bgProps={{ video: [bgVideo], position: '50% 20%' }}>
      <Flex alignItems="center" justifyContent="between">
        <h3 className="fs-1 mb-3">Zaloguj się</h3>
      </Flex>
      <LoginForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default Login;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import EquipmentTableHeader from './EquipmentTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import useTranslation from 'hooks/useTranslation';
import Lottie from 'lottie-react';
import warningLight from 'assets/img/animated-icons/warning-light.json';

const columns = [
  {
    accessor: 'equipmentId',
    Header: 'Numer id',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { equipmentId } = rowData.row.original;
      return <p className="mb-0">#{equipmentId}</p>;
    }
  },
  {
    accessor: 'malfunction',
    disableSortBy: true,
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { malfunction } = rowData.row.original;

      if (!malfunction) return;
      return (
        <Lottie
          animationData={warningLight}
          style={{ height: '32px', width: '32px', margin: '-16px 0' }}
        />
      );
    }
  },
  {
    accessor: 'name',
    Header: 'Nazwa urządzenia',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name, malfunction } = rowData.row.original;
      return (
        <>
          <Link
            className={malfunction ? 'text-warning' : ''}
            to={`/equipment/${_id}`}
          >
            <strong>{name}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'catalogNumber',
    Header: 'Numer katalogowy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { catalogNumber } = rowData.row.original;
      return <p className="mb-0">{catalogNumber}</p>;
    }
  },
  {
    accessor: 'serialNumber',
    Header: 'Numer seryjny',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { serialNumber } = rowData.row.original;
      return <p className="mb-0">{serialNumber}</p>;
    }
  },
  {
    accessor: 'inventoryNumber',
    Header: 'Numer inwentarzowy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { inventoryNumber } = rowData.row.original;
      return <p className="mb-0">{inventoryNumber}</p>;
    }
  },
  {
    accessor: 'manufacturer',
    Header: 'Producent',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { manufacturer } = rowData.row.original;
      return <p className="mb-0">{manufacturer?.name}</p>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id, malfunction } = rowData.row.original;
      const { handleDeleteClick, handleMakeMalfunction, loadingRows } =
        rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item as={Link} to={`/equipment/${_id}`}>
              Szczegóły
            </Dropdown.Item>
            <Dropdown.Divider as="div" />
            <Dropdown.Item as={Link} to={`/create-equipment/${_id}`}>
              Edycja
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className={malfunction ? 'text-success' : 'text-danger'}
              onClick={() => handleMakeMalfunction(_id, malfunction)}
            >
              {`Oznacz jako ${malfunction ? 'sprawne' : 'niesprawne'}`}
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handleDeleteClick(_id)}
            >
              Usuń
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const EquipmentList = ({ loadingRows, setRowLoading }) => {
  const [equipmentList, setEquipmentList] = useState([]);
  const [queryParams, setQueryParams] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment?${queryParams}`
      );
      setEquipmentList(response.data.equipment);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  const handleMakeMalfunction = async (id, malfunction) => {
    try {
      setRowLoading(id, true);
      await axios.put(`${process.env.REACT_APP_API_URL}/equipment/${id}`, {
        malfunction: !malfunction
      });
      toast.success('Oznaczono urządzenie jako niesprawne');
      setEquipmentList(
        equipmentList.map(equipment =>
          equipment._id === id
            ? { ...equipment, malfunction: !malfunction }
            : equipment
        )
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setRowLoading(id, false);
    }
  };

  const handleDeleteClick = async id => {
    let confirmation = window.confirm('Czy na pewno chcesz usunąć to zadanie?');

    if (confirmation) {
      try {
        setRowLoading(id, true);
        await axios.delete(`${process.env.REACT_APP_API_URL}/equipment/${id}`);
        toast.success('Usunięto urządzenie');
        setEquipmentList(
          equipmentList.filter(equipment => equipment._id !== id)
        );
      } catch (error) {
        console.error('Error deleting data:', error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      } finally {
        setRowLoading(id, false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [queryParams]);

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        handleDeleteClick,
        handleMakeMalfunction,
        loadingRows
      }))}
      data={equipmentList}
      selection
      sortable
      pagination
      perPage={25}
    >
      <Card className="mb-3">
        <Card.Header>
          <EquipmentTableHeader
            table
            setEquipmentList={setEquipmentList}
            equipmentList={equipmentList}
            setQueryParams={setQueryParams}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

EquipmentList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(EquipmentList);

import React from 'react';
import PageHeader from 'components/common/PageHeader';
import changeLogs from '../../../data/changelog';
import Logs from './Logs';

const Changelog = () => (
  <>
    <PageHeader title="Lista zmian" className="mb-3" />

    {changeLogs.map((logs, index) => (
      <Logs {...logs} index={index} key={index} />
    ))}
  </>
);

export default Changelog;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import TaskDetails from './TaskDetails';
import TaskStock from './TaskStock';
import TaskBanner from './TaskBanner';
import TaskUpload from './TaskUpload';
import { Col, Form, Row } from 'react-bootstrap';
import TaskOtherInfo from './TaskOtherInfo';
import axios from 'axios';
import { useForm, useWatch } from 'react-hook-form';
import useTranslation from 'hooks/useTranslation';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'components/common/LoadingSpinner';

const CreateTask = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [stockItems, setStockItems] = useState([]);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null
  });

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const equipmentID = params.get('equipmentID');
  const equipmentName = params.get('equipmentName');
  const userID = params.get('userID');
  const userName = params.get('userName');

  const navigate = useNavigate();

  const defaultValues = {
    users: [],
    uploadedFiles: [],
    categories: []
  };

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  const locationId = useWatch({ control, name: 'locationId' });

  useEffect(() => {
    if (equipmentID && equipmentName) {
      setValue('equipment', { value: equipmentID, label: equipmentName });
    }
  }, [equipmentID, equipmentName, setValue]);

  useEffect(() => {
    if (userID && userName) {
      setValue('users', { value: userID, label: userName });
    }
  }, [userID, userName, setValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/tasks/${id}`
          );
          const task = response.data.task;

          if (task) {
            setValue('startDate', new Date(task.startDate));
            setValue('endDate', new Date(task.endDate));
            setDates({
              startDate: new Date(task.startDate),
              endDate: new Date(task.endDate)
            });
            setValue('name', task.name);
            setValue('locationName', task.locationName);
            setValue('city', task.city);
            setValue('streetName', task.streetName);
            setValue('zip', task.zip);
            setValue('country', task.country);
            setValue('description', task.description);
            setValue('equipment', {
              value: task.equipment._id,
              label: task.equipment.name
            });
            setValue(
              'users',
              task.users.map(user => ({
                value: user._id,
                label: user.firstName + ' ' + user.lastName
              }))
            );
            setValue('type', { value: task.type, label: task.type });
            setValue(
              'checklist',
              task.checklist.map(checklist => ({
                value: checklist._id,
                label: checklist.name
              }))
            );
            setValue(
              'categories',
              task.categories.map(category => ({
                value: category._id,
                label: category.name
              }))
            );
            task.stockItems.forEach((item, index) => {
              setValue(`stockItems[${index}].name`, item.name);
              setValue(`stockItems[${index}].count`, item.count);
              setValue(`stockItems[${index}].checked`, item.checked);
            });
            setStockItems(task.stockItems);
            setValue('privacy', task.privacy);
            setValue('priority', task.priority);
            setValue('enableTaskNotifications', task.enableTaskNotifications);
            setValue('uploadedFiles', '');
            setStatus(task.status);
          }
        }
      } catch (error) {
        console.error('Failed to fetch task', error);
        toast.error('Błąd podczas pobierania zadania');
      }
    };

    fetchData();
  }, [id, setValue]);

  const onSubmit = async data => {
    setIsLoading(true);

    if (locationId) {
      delete data.locationName;
      delete data.streetName;
      delete data.city;
      delete data.zip;
      delete data.country;
    }

    const formData = new FormData();
    for (let key in data) {
      if (key === 'categories') {
        if (data[key] && data[key].length > 0) {
          data[key].map(category => {
            formData.append('categories', category.value);
          });
        }
      } else if (key === 'stockItems') {
        formData.append('stockItems', JSON.stringify(data[key]));
      } else if (key === 'checklist') {
        formData.append('checklist', JSON.stringify(data[key]));
      } else if (key === 'equipment') {
        formData.append('equipment', data[key].value);
      } else if (key === 'locationId') {
        formData.append('locationId', data[key].value);
      } else if (key === 'type') {
        formData.append('type', data[key].value);
      } else if (key === 'uploadedFiles') {
        for (let file of data[key]) {
          formData.append('uploadedFiles', file);
        }
      } else if (key === 'users') {
        data[key].map(user => {
          formData.append('users', user.value);
        });
      } else {
        formData.append(key, data[key]);
      }
    }

    formData.append('status', status);

    try {
      if (id) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/tasks/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/tasks/add`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      }
      toast.success(id ? 'Zadanie zostało zapisane' : 'Zadanie zostało dodane');
    } catch (error) {
      console.error('Failed to create/update user', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
      navigate('/task-list', { replace: true });
    }
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
      <Row className="g-3">
        <Col xs={12}>
          <TaskBanner
            title={id ? 'Edytuj zadanie' : 'Dodaj nowe zadanie'}
            status={status}
            isLoading={isLoading}
            onSubmit={setStatus}
          />
        </Col>
        <Col lg={8}>
          <TaskDetails
            register={register}
            setValue={setValue}
            errors={errors}
            dates={dates}
            control={control}
          />
          <TaskStock
            stockItems={stockItems}
            setStockItems={setStockItems}
            register={register}
            unregister={unregister}
            control={control}
            setValue={setValue}
          />
          <TaskUpload register={register} setValue={setValue} />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar mb-3">
            <TaskOtherInfo
              register={register}
              control={control}
              errors={errors}
            />
          </div>
        </Col>
        <Col>
          <TaskBanner
            title="Dobra robota! Zadanie jest prawie gotowe."
            isLoading={isLoading}
            onSubmit={setStatus}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default CreateTask;

import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import localePl from '@fullcalendar/core/locales/pl';
import interactionPlugin from '@fullcalendar/interaction';
import AppContext from 'context/Context';
import AddScheduleModal from 'components/app/calendar/AddScheduleModal';
import CalendarTaskModal from 'components/app/calendar/CalendarTaskModal';
import Flex from 'components/common/Flex';
import useTranslation from 'hooks/useTranslation';
import { toast } from 'react-toastify';
import axios from 'axios';

import { formatDate } from 'components/app/calendar/Calendar';

const CalendarManagement = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const calendarRef = useRef();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [day, setDay] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);
  const [modalTaskContent, setModalTaskContent] = useState({});
  const [scheduleStartDate, setScheduleStartDate] = useState();
  const [scheduleEndDate, setScheduleEndDate] = useState();

  const formatTask = useMemo(() => {
    return events => {
      return events.map(event => {
        const {
          _id: id,
          name: title,
          startDate,
          endDate,
          description,
          location,
          status
        } = event;
        const formattedLocation = location.formattedAddress || '';
        const formattedStartTime = formatDate(startDate, false);
        const formattedEndTime = formatDate(endDate, false);

        let classNames = 'border border-2 bg-100 border-';

        switch (status) {
          case 'completed':
            classNames += 'success';
            break;
          case 'active':
            classNames += 'primary';
            break;
          case 'onhold':
          case 'archived':
            classNames += 'warning';
            break;
          case 'pending':
          case 'draft':
            classNames += 'secondary';
            break;
          default:
            classNames += 'primary';
            break;
        }

        return {
          id,
          title,
          start: formattedStartTime,
          end: formattedEndTime,
          extendedProps: {
            description,
            location: formattedLocation,
            organizer: 'Polska'
          },
          display: 'background',
          color: 'primary',
          classNames
        };
      });
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks`
      );
      setData(formatTask(response.data.tasks));
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleTaskClick = eventsInfo => {
    setModalTaskContent({
      event: calendarApi.getEventById(
        eventsInfo?.id ?? eventsInfo?.event?._def?.publicId
      )
    });
    setIsOpenTaskModal(true);
  };

  useEffect(() => {
    fetchData();
    setCalendarApi(calendarRef.current.getApi());
  }, []);

  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: false
  };

  const getDate = () => {
    return calendarApi.getCurrentData().currentDate.toLocaleString('pl-PL', {
      weekday: 'long'
    });
  };

  return (
    <>
      <Card className="overflow-hidden h-100">
        <Card.Body className="p-0 management-calendar">
          <Row className="g-3">
            <Col md={7}>
              <div className="p-x1">
                <Flex justifyContent="between">
                  <div className="order-md-1">
                    <OverlayTrigger
                      overlay={<Tooltip id="nextTooltip">Poprzedni</Tooltip>}
                    >
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="me-1"
                        onClick={() => {
                          calendarApi.prev();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                          setDay(getDate);
                        }}
                      >
                        <FontAwesomeIcon icon="chevron-left" />
                      </Button>
                    </OverlayTrigger>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      onClick={() => {
                        calendarApi.today();
                        setTitle(calendarApi.getCurrentData().viewTitle);
                        setDay(getDate);
                      }}
                      className="px-sm-4"
                    >
                      Dziś
                    </Button>
                    <OverlayTrigger
                      overlay={<Tooltip id="nextTooltip">Następny</Tooltip>}
                    >
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          calendarApi.next();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                          setDay(getDate);
                        }}
                      >
                        <FontAwesomeIcon icon="chevron-right" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </Flex>
              </div>
              <div className="calendar-outline px-3">
                <FullCalendar
                  ref={calendarRef}
                  headerToolbar={false}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  themeSystem="bootstrap"
                  dayMaxEvents={2}
                  eventTimeFormat={eventTimeFormat}
                  direction={isRTL ? 'rtl' : 'ltr'}
                  height={360}
                  events={data}
                  locale={localePl}
                  initialView="dayGridMonth"
                  eventClick={handleTaskClick}
                />
              </div>
            </Col>
            <Col md={5} className="bg-light pt-3">
              <div className="px-3">
                <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                  {title ||
                    `${calendarApi.currentDataManager?.data?.viewTitle}`}
                </h4>
                <p className="text-500 mb-0">
                  {day ||
                    `${new Date().toLocaleString('pl-PL', {
                      weekday: 'long'
                    })}`}
                </p>
                <ul
                  className="list-unstyled mt-3 scrollbar management-calendar-events"
                  id="management-calendar-events"
                >
                  {data.map(events => (
                    <li
                      className="border-top pt-3 mb-3 pb-1 cursor-pointer"
                      onClick={() => handleTaskClick(events)}
                      key={events.id}
                    >
                      <div
                        className={`border-start border-3 ps-3 mt-1 border-${events.color}`}
                      >
                        <h6 className="mb-1 fw-semi-bold text-700">
                          {events.title}
                        </h6>
                        <p className="fs--2 text-600 mb-0">
                          {events.startTime || ''} {events.endTime ? '- ' : ''}
                          {events.endTime || ''}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <AddScheduleModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        scheduleStartDate={scheduleStartDate}
        scheduleEndDate={scheduleEndDate}
        setScheduleStartDate={setScheduleStartDate}
        setScheduleEndDate={setScheduleEndDate}
      />

      <CalendarTaskModal
        isOpenTaskModal={isOpenTaskModal}
        setIsOpenTaskModal={setIsOpenTaskModal}
        modalTaskContent={modalTaskContent}
      />
    </>
  );
};

export default CalendarManagement;

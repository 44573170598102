import { Collapse, Form, Col, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { Controller, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import pl from 'date-fns/locale/pl';

export const ChecklistFilters = ({ open, setQueryParams }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      role: [],
      categories: []
    }
  });
  const [categoriesList, setCategoriesList] = useState([]);
  const [formData, setFormData] = useState({
    createdAt: null
  });

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onSubmit = data => {
    let queryParts = [];

    if (data.name) {
      queryParts.push(`q=${encodeURIComponent(data.name)}`);
    }

    if (data.categories?.length > 0) {
      data.categories.map(category => {
        queryParts.push(`categories[in]=${category.value}`);
      });
    }

    if (data.createdAt) {
      queryParts.push(
        `createdAt[gte]=${new Date(data.createdAt).toISOString()}`
      );
    }

    setQueryParams(queryParts?.join('&') || '');
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/categories`
      );
      const categories = response.data.categories.map(category => ({
        value: category._id,
        label: category.name
      }));
      setCategoriesList(categories);
    } catch (error) {
      console.error(error);
      toast.error('Błąd podczas pobierania listy kategorii');
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Collapse in={open}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="name" className="mb-3 d-flex flex-column">
              <Form.Label>Nazwa checklisty</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nazwa checklisty"
                isInvalid={!!errors.name}
                {...register('name')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group
              className="mb-3 d-flex flex-column"
              controlId="createdAt"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Form.Label>Data utworzenia</Form.Label>
              <DatePicker
                selected={formData.createdAt}
                showTimeSelect
                timeFormat="HH:mm"
                locale={pl}
                timeIntervals={60}
                dateFormat="dd/MM/yyyy HH:mm"
                isClearable
                timeCaption="Czas:"
                onChange={newDate => {
                  if (formData.createdAt === newDate) {
                    handleChange('createdAt', '');
                    setValue('createdAt', '');
                  } else {
                    handleChange('createdAt', newDate);
                    setValue('createdAt', newDate);
                  }
                }}
                customInput={
                  <CustomDateInput
                    isInvalid={!!errors.createdAt}
                    errorMessage={errors.createdAt?.message}
                    formControlProps={{
                      placeholder: 'd/m/y HH:mm',
                      ...register('createdAt')
                    }}
                  />
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group
              controlId="categories"
              className="mb-3 d-flex flex-column"
            >
              <Form.Label>Kategorie</Form.Label>
              <Controller
                name="categories"
                render={({ field, ref }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={false}
                    isMulti
                    options={categoriesList}
                    placeholder="Wybierz kategorię..."
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Flex
              justifyContent="end"
              alignItems="end"
              style={{
                height: '100%'
              }}
            >
              <Button
                size="sm"
                variant="falcon-primary"
                type="submit"
                transform="shrink-3"
              >
                <span>Zastosuj filtry</span>
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Collapse>
  );
};

ChecklistFilters.propTypes = {
  open: PropTypes.bool,
  setQueryParams: PropTypes.func
};

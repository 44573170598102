import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';

const TaskDetailGallery = ({ images }) => {
  return (
    <FalconLightBoxGallery images={images}>
      {setImgIndex => (
        <Row className="g-2">
          {images.map((image, index) => (
            <Col xs={image.length <= 2 ? 6 : 4} key={index}>
              <Image
                src={`${process.env.REACT_APP_FILE_HOST}${image.url}`}
                fluid
                rounded
                onClick={() => setImgIndex(index)}
              />
            </Col>
          ))}
        </Row>
      )}
    </FalconLightBoxGallery>
  );
};

TaskDetailGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
};

export default TaskDetailGallery;

import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import axios from 'axios';
import useTranslation from 'hooks/useTranslation';
import { useNavigate } from 'react-router-dom';

const ChecklistBanner = ({ title, isLoading, itemID }) => {
  const navigate = useNavigate();

  const handleDuplicateClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/checklists/${itemID}`
      );
      const newChecklist = {
        ...response.data.checklist,
        name: `${response.data.checklist.name} - kopia`
      };
      delete newChecklist._id;
      const duplicateResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/tasks/checklists/add`,
        newChecklist
      );
      navigate(`/checklist/${duplicateResponse.data.checklist._id}`);
      toast.success(useTranslation(response.data.message, 'pl'), {
        theme: 'colored'
      });
    } catch (error) {
      console.error('Error duplicating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleDeleteClick = async () => {
    const confirmDelete = window.confirm(
      'Jesteś pewien że chcesz usunąć tą checkliste? Tej operacji nie można cofnąć. Czy chcesz kontynuować?'
    );
    if (confirmDelete) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/tasks/checklists/${itemID}`
        );
        navigate('/task-checklist-list');
        toast.success(useTranslation(response.data.message, 'pl'), {
          theme: 'colored'
        });
      } catch (error) {
        console.error('Error deleting data:', error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      }
    }
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>{title && <h5 className="mb-2 mb-md-0">{title}</h5>}</Col>
          <Col xs="auto" className="mt-4 mt-md-0">
            <Flex>
              {itemID && (
                <>
                  <Button
                    size="sm"
                    variant="falcon-info"
                    className="me-2"
                    onClick={handleDuplicateClick}
                    disabled={isLoading}
                  >
                    Zduplikuj
                  </Button>
                  <Button
                    size="sm"
                    variant="falcon-danger"
                    className="me-2"
                    onClick={handleDeleteClick}
                    disabled={isLoading}
                  >
                    Usuń
                  </Button>
                </>
              )}
              <Button
                size="sm"
                variant="falcon-default"
                icon="share-alt"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? '  Zapisuję...' : 'Zapisz'}
              </Button>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ChecklistBanner.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  itemID: PropTypes.string
};

export default ChecklistBanner;

import React from 'react';
import PropTypes from 'prop-types';
import { userPropTypes } from 'shapes/userPropTypes';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useParams } from 'react-router-dom';

const AccountDetails = ({ register, errors, isLoading, onSubmit }) => {
  const { userId } = useParams();
  return (
    <Card>
      <FalconCardHeader
        title={userId ? 'Edytuj użytkownika' : 'Dodaj użytkownika'}
      />
      <Card.Body className="bg-light">
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={6} controlId="firstName">
            <Form.Label>Imię</Form.Label>
            <Form.Control
              type="text"
              placeholder="Imię"
              name="firstName"
              {...register('firstName', {
                required: true
              })}
              className={errors.firstName ? 'is-invalid' : ''}
            />
          </Form.Group>

          <Form.Group as={Col} lg={6} controlId="lastName">
            <Form.Label>Nazwisko</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nazwisko"
              name="lastName"
              {...register('lastName', { required: true })}
              className={errors.lastName ? 'is-invalid' : ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={6} controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              {...register('email', { required: true })}
              className={errors.email ? 'is-invalid' : ''}
            />
          </Form.Group>

          <Form.Group as={Col} lg={6} controlId="phone">
            <Form.Label>Telefon</Form.Label>
            <Form.Control
              type="text"
              placeholder="Telefon"
              name="phone"
              {...register('phone', { required: true })}
              className={errors.email ? 'is-invalid' : ''}
            />
          </Form.Group>
        </Row>

        <Form.Group className="mb-3" controlId="address">
          <Form.Label>Adres</Form.Label>
          <Form.Control
            type="text"
            placeholder="Adres"
            name="address"
            {...register('address', { required: true })}
            className={errors.address ? 'is-invalid' : ''}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="position">
          <Form.Label>Stanowisko</Form.Label>
          <Form.Control
            type="text"
            placeholder="Stanowisko"
            name="position"
            {...register('position')}
          />
        </Form.Group>
        <div className="text-end">
          <Button variant="primary" disabled={isLoading} onClick={onSubmit}>
            {isLoading ? '  Zapisuję...' : 'Zapisz'}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

AccountDetails.propTypes = {
  user: userPropTypes,
  isLoading: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AccountDetails;

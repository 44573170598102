import React from 'react';
import PropTypes from 'prop-types';
import SoftBadge from './SoftBadge';

export const statusLabelsMap = {
  completed: 'Zakończone',
  active: 'W trakcie',
  pending: 'Do potwierdzenia',
  onhold: 'Wstrzymane',
  archived: 'Zarchiwizowane',
  draft: 'Szkic'
};

const statusColorsMap = {
  completed: 'success',
  active: 'primary',
  pending: 'secondary',
  draft: 'secondary',
  onhold: 'warning',
  archived: 'warning'
};

const StatusBadge = ({ status, ...rest }) => {
  return (
    <SoftBadge
      pill
      bg={statusColorsMap[status]}
      classname={`d-block border border-${statusColorsMap[status]}-subtle ${
        rest.checked && `border border-${statusColorsMap[status]}`
      }`}
      {...rest}
    >
      {statusLabelsMap[status]}
    </SoftBadge>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired
};

export default StatusBadge;

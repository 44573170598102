import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import useFormatDate from 'hooks/useFormatDate';

const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ms-n1 me-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon
      icon={icon}
      transform={transform ?? ''}
      className="text-primary fa-stack-1x"
      inverse
    />
  </span>
);

const TaskModalMediaContent = ({ icon, heading, content, children }) => (
  <Flex className="mt-3">
    {getCircleStackIcon(icon)}
    <div className="flex-1">
      <h6>{heading}</h6>
      {children || <p className="mb-0 text-justify">{content}</p>}
    </div>
  </Flex>
);

const CalendarTaskModal = ({
  setIsOpenTaskModal,
  isOpenTaskModal,
  modalTaskContent
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const handleClose = () => {
    setIsOpenTaskModal(!isOpenTaskModal);
  };

  const { title, end, start } =
    (isOpenTaskModal && modalTaskContent?.event) || false;
  const { description, location, organizer, schedules } =
    (isOpenTaskModal && modalTaskContent?.event?.extendedProps) || false;

  const [formattedStartDate, formattedStartTime] = useFormatDate(start, false);
  const [formattedEndDate, formattedEndTime] = useFormatDate(end, false);

  return (
    <Modal
      show={isOpenTaskModal}
      onHide={handleClose}
      contentClassName="border"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title>
          <h5 className="mb-0">{title}</h5>
          {organizer && (
            <p className="mb-0 fs--1 mt-1 fw-normal">
              by <a href="#!">{organizer}</a>
            </p>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-x1 pb-x1 pt-1 fs--1">
        {description && (
          <TaskModalMediaContent
            icon="align-left"
            heading="Opis"
            content={description}
          />
        )}
        {(end || start) && (
          <TaskModalMediaContent icon="calendar-check" heading="Data i czas">
            <p className="mb-1">
              {`${formattedStartDate} ${formattedStartTime}`}
              {end && (
                <>
                  <br />
                  {`${formattedEndDate} ${formattedEndTime}`}
                </>
              )}
            </p>
          </TaskModalMediaContent>
        )}
        {location && (
          <TaskModalMediaContent icon="map-marker-alt" heading="Miejsce">
            <div
              className="mb-1"
              dangerouslySetInnerHTML={{ __html: location }}
            />
          </TaskModalMediaContent>
        )}
        {schedules && (
          <TaskModalMediaContent icon="clock" heading="Schedule">
            <ul className="list-unstyled timeline mb-0">
              {schedules.map((schedule, index) => (
                <li key={index}>{schedule.title}</li>
              ))}
            </ul>
          </TaskModalMediaContent>
        )}
      </Modal.Body>
      <Modal.Footer className="bg-light px-x1 border-top-0">
        <Button
          as={Link}
          to={`/create-task/${modalTaskContent?.event?._def?.publicId}`}
          variant="falcon-default"
          size="sm"
        >
          <FontAwesomeIcon icon="pencil-alt" className="fs--2 me-2" />
          <span>Edytuj</span>
        </Button>
        <Button
          as={Link}
          to={`/task/${modalTaskContent?.event?._def?.publicId}`}
          variant="falcon-primary"
          size="sm"
        >
          <span>Pokaż więcej szczegółów</span>
          <FontAwesomeIcon icon="angle-right" className="fs--2 ms-1" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TaskModalMediaContent.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node
};

CalendarTaskModal.propTypes = {
  setIsOpenTaskModal: PropTypes.func.isRequired,
  isOpenTaskModal: PropTypes.bool.isRequired,
  modalTaskContent: PropTypes.object.isRequired
};

export default CalendarTaskModal;

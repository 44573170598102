import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const MultiSelect = forwardRef(
  ({ options, placeholder, isInvalid, isDisabled, ...rest }, ref) => {
    return (
      <Select
        ref={ref}
        closeMenuOnSelect={false}
        isMulti
        options={options}
        isDisabled={isDisabled}
        placeholder={placeholder}
        classNamePrefix="react-select"
        styles={{
          control: base => {
            return {
              ...base,
              borderColor: isInvalid && '#e63757 !important'
            };
          },
          singleValue: base => {
            return {
              ...base,
              color: isDisabled && '#595e66 !important'
            };
          }
        }}
        {...rest}
      />
    );
  }
);

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default MultiSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import IconButton from 'components/common/IconButton';

import { v4 as uuid } from 'uuid';
import ChecklistCustomFieldItem from './ChecklistCustomFieldItem';

const ChecklistCustomField = ({
  register,
  setValue,
  checklistFields,
  onCustomFieldsChange
}) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('text');
  const [options, setOptions] = useState('');
  const [hasOptions, setHasOptions] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [customFields, setCustomFields] = useState([]);

  const handleRemove = id => {
    const items = customFields.filter(fields => fields.id !== id);
    setCustomFields(items);
    onCustomFieldsChange(items);
  };

  const handleMoveUp = index => {
    if (index === 0) return;
    setCustomFields(prevFields => {
      const newFields = [...prevFields];
      [newFields[index - 1], newFields[index]] = [
        newFields[index],
        newFields[index - 1]
      ];
      onCustomFieldsChange(newFields);
      return newFields;
    });
  };

  const handleMoveDown = index => {
    if (index === customFields.length - 1) return;
    setCustomFields(prevFields => {
      const newFields = [...prevFields];
      [newFields[index + 1], newFields[index]] = [
        newFields[index],
        newFields[index + 1]
      ];
      onCustomFieldsChange(newFields);
      return newFields;
    });
  };

  const handleSubmit = () => {
    let newFields = [];

    if (hasOptions) {
      const updatedOptions = options.split(',');
      newFields = [
        ...customFields,
        { name, type, options: updatedOptions, id: uuid() }
      ];
    } else {
      newFields = [...customFields, { name, type, id: uuid() }];
    }

    setCustomFields(newFields);
    onCustomFieldsChange(newFields);

    setName('');
    setType('text');
    setOptions('');
    setHasOptions(false);
    setDisabled(true);
  };

  // Handler
  useEffect(() => {
    setHasOptions(
      type === 'select' || type === 'checkboxes' || type === 'radio'
    );
  }, [type]);

  useEffect(() => {
    let disableState = true;
    if (!hasOptions && name.length) {
      disableState = false;
    }
    if (hasOptions && options.length && name.length) {
      disableState = false;
    }
    setDisabled(disableState);
  }, [name, type, hasOptions, options]);

  useEffect(() => {
    if (checklistFields && checklistFields.length > 0) {
      setCustomFields(checklistFields);
    }
  }, [checklistFields]);

  return (
    <Card>
      <Card.Header as="h5">Pola checklisty</Card.Header>
      {customFields.length > 0 && (
        <Card.Body>
          {customFields.map((customField, index) => (
            <ChecklistCustomFieldItem
              {...customField}
              register={register}
              index={index}
              setValue={setValue}
              key={customField.id}
              handleRemove={handleRemove}
              handleMoveUp={() => handleMoveUp(index)}
              handleMoveDown={() => handleMoveDown(index)}
            />
          ))}
        </Card.Body>
      )}
      <Card.Body className="bg-light">
        <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group controlId="name">
                <Form.Label>Nazwa pola</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder=""
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Typ</Form.Label>
                <Form.Select
                  size="sm"
                  aria-label="Default select example"
                  value={type}
                  onChange={({ target }) => setType(target.value)}
                >
                  <option value="text">Tekstowe</option>
                  <option value="number">Liczbowe</option>
                  <option value="email">E-mail</option>
                  <option value="checkboxes">Pola wielokrotnego wyboru</option>
                  <option value="radio">Pola pojedynczego wyboru</option>
                  <option value="textarea">Pole opisowe</option>
                  <option value="select">Wybór</option>
                  <option value="boolean">Tak/Nie</option>
                </Form.Select>
              </Form.Group>
            </Col>
            {hasOptions && (
              <Col md="12">
                <Form.Group>
                  <Form.Label>Opcje Pola</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={options}
                    onChange={({ target }) => setOptions(target.value)}
                  />
                  <Form.Text className="text-warning fs--1">
                    * Oddziel opcje przecinkiem
                  </Form.Text>
                </Form.Group>
              </Col>
            )}
            <Col>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                disabled={disabled}
                transform="shrink-3"
                onClick={() => handleSubmit()}
              >
                Dodaj pole
              </IconButton>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

ChecklistCustomField.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  checklistFields: PropTypes.array,
  onCustomFieldsChange: PropTypes.func.isRequired
};

export default ChecklistCustomField;

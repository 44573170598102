import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import useFormatDate from 'hooks/useFormatDate';
import FalconCardHeader from 'components/common/FalconCardHeader';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBarReact from 'simplebar-react';

import ActivityContext from 'context/activities/ActivityContext';

import {
  buildActivityObject,
  createActivityLinkMarkup,
  EXCLUDED_ACTIVITY_TYPES
} from 'helpers/activity-utils';

export const Activity = ({ activity, isLast }) => {
  const [formattedDate, formattedTime] = useFormatDate(
    activity?.createdAt,
    false
  );

  if (!activity || EXCLUDED_ACTIVITY_TYPES.includes(activity.item.type))
    return null;

  const activityObject = buildActivityObject(activity);

  return (
    <Row
      className={classNames(
        'g-3 recent-activity-timeline recent-activity-timeline-primary',
        {
          'pb-x1': !isLast
        }
      )}
    >
      <Col xs="auto" className="ps-4 ms-2">
        <div className="ps-2">
          <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
            <FontAwesomeIcon
              icon={activityObject.icon}
              className="text-primary"
            />
          </div>
        </div>
      </Col>
      <Col>
        <Row className={classNames('g-3', { 'border-bottom pb-x1': !isLast })}>
          <Col>
            <h6 className="text-800 mb-1">
              {createActivityLinkMarkup(activityObject)}
            </h6>
          </Col>
          <Col xs="auto">
            <p className="fs--2 text-500 mb-0">
              {`${formattedDate} ${formattedTime}`}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RecentActivity = () => {
  const { activities } = useContext(ActivityContext);

  return (
    <Card className="h-100 recent-activity-card">
      <FalconCardHeader title="Ostatnia aktywność" titleTag="h6" />
      <SimpleBarReact style={{ height: '26rem' }}>
        <Card.Body className="ps-2 recent-activity-body-height">
          {activities.map((activity, index) => (
            <Activity
              key={index}
              activity={activity}
              isLast={index === activities.length - 1}
            />
          ))}
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  isLast: PropTypes.bool
};

RecentActivity.propTypes = {
  data: PropTypes.arrayOf(Activity.propTypes.activity)
};

export default RecentActivity;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Placeholder, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Divider from 'components/common/Divider';

import AuthContext from 'context/auth/AuthContext';

import useFormatDate from 'hooks/useFormatDate';
import copyToClipboard from 'helpers/copyToClipboard';

const ProfileInfo = ({ profile }) => {
  const { user } = React.useContext(AuthContext);

  if (!profile) {
    return (
      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Szczegóły konta</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light border-top">
          <Placeholder as={Row} animation="glow" />
        </Card.Body>
      </Card>
    );
  }

  const {
    _id,
    position,
    email,
    phone,
    address,
    description,
    createdAt,
    recentActivity
  } = profile;

  const [createdAtDate] = useFormatDate(createdAt, false);
  const [recentActivityDate] = useFormatDate(recentActivity, false);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5 className="mb-0">Szczegóły konta</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-light border-top">
        <Row>
          <Col lg xxl={5}>
            <Row>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Stanowisko</p>
              </Col>
              <Col>
                <p>{position}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Email</p>
              </Col>
              <Col>
                <p>
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Numer telefonu</p>
              </Col>
              <Col>
                <p>
                  <a href={`tel:${phone?.replace(/\s/g, '')}`}>{phone}</a>
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg xxl={{ span: 5, offset: 1 }} className="mt-4 mt-lg-0">
            <Row>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Adres</p>
              </Col>
              <Col>
                <p>
                  {address?.split(',').map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Opis</p>
              </Col>
              <Col>
                <p>
                  {!description ? (
                    <span className="text-500">Brak opisu</span>
                  ) : (
                    description
                  )}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xs={6} sm={2}>
            <p className="fw-semi-bold mb-1 fs--1">ID</p>
          </Col>
          <Col xs={6} sm={3}>
            <p
              className="mb-1 fs--1 cursor-pointer"
              title={_id}
              onClick={() => copyToClipboard(_id)}
            >
              #{_id}{' '}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={2}>
            <p className="fw-semi-bold mb-1 fs--1">Utworzono</p>
          </Col>
          <Col xs={6} sm={2}>
            <p className="mb-1 fs--1">{createdAtDate}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={2}>
            <p className="fw-semi-bold mb-1 fs--1">Ostatnia aktywność</p>
          </Col>
          <Col xs={6} sm={2}>
            <p className="mb-1 fs--1">{recentActivityDate}</p>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="border-top text-end">
        {(user._id === _id || ['admin', 'superadmin'].includes(user.role)) && (
          <Button
            variant="falcon-default"
            size="sm"
            as={Link}
            to={`/create-user/${_id}`}
          >
            Edytuj dane
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

ProfileInfo.propTypes = {
  profile: PropTypes.object
};

export default ProfileInfo;

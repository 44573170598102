import React from 'react'; // needed to transpile JSX
import { Link } from 'react-router-dom';
import { captureMessage as SentryMessage } from '@sentry/react';

const ACTIVITY_ACTIONS = {
  create: {
    label: 'utworzył'
  },
  edit: {
    label: 'edytował'
  },
  update: {
    label: 'zaktualizował'
  },
  archive: {
    label: 'zarchiwizował'
  },
  delete: {
    label: 'usunął'
  },
  add: {
    label: 'dodał'
  },
  finish: {
    label: 'zakończył'
  },
  start: {
    label: 'rozpoczął'
  }
};

const ACTIVITY_ITEM_TYPES = {
  task: {
    label: 'zadanie',
    icon: 'tasks'
  },
  equipment: {
    label: 'sprzęt',
    icon: 'tools'
  },
  user: {
    label: 'użytkownika',
    icon: 'user'
  },
  checklist: {
    label: 'listę kontrolną',
    icon: 'clipboard-check'
  },
  location: {
    label: 'lokalizację',
    icon: 'map-marker-alt'
  }
};

export const EXCLUDED_ACTIVITY_TYPES = ['location'];

export const buildActivityObject = ({ user, action, item }) => {
  const activityObject = {
    user: {
      name: `${user.firstName} ${user.lastName}`,
      link: `/user/${user._id}`
    },
    name: '',
    icon: 'triangle-exclamation',
    link: '',
    itemName: item?.name
  };

  if (!ACTIVITY_ACTIONS[action]) {
    SentryMessage(`Activity action '${action}' not found in definitions`);
    return {
      ...activityObject,
      name: 'wykonał nieznaną akcję z'
    };
  }

  if (!ACTIVITY_ITEM_TYPES[item.type]) {
    SentryMessage(`Activity item type '${item.type}' not found in definitions`);
    return {
      ...activityObject,
      name: 'wykonał akcję z nieznanym obiektem'
    };
  }

  activityObject.name =
    ACTIVITY_ACTIONS[action].label + ' ' + ACTIVITY_ITEM_TYPES[item.type].label;
  activityObject.icon = ACTIVITY_ITEM_TYPES[item.type].icon;
  activityObject.link = action !== 'delete' ? `/${item.type}/${item.id}` : '';

  return activityObject;
};

export const createActivityLinkMarkup = activityObject => {
  return (
    <>
      <Link to={activityObject.user.link}>{activityObject.user.name}</Link>{' '}
      {activityObject.name}{' '}
      {activityObject.link ? (
        <Link to={activityObject.link}>{activityObject.itemName}</Link>
      ) : (
        activityObject.itemName
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import RunningTasks from 'components/dashboards/project-management/RunningTasks';

const EquipmentLog = ({ equipmentId }) => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tasks/?equipment=${equipmentId}`
        );
        setTasks(response.data.tasks);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLogs();
  }, []);

  return <RunningTasks tasks={tasks} title="Historia zadań" />;
};

EquipmentLog.propTypes = {
  equipmentId: PropTypes.string.isRequired
};

export default EquipmentLog;

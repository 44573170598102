import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';

const LockScreenForm = ({ type, userName, ...rest }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    // setRedirect(true);
    toast.success(`Zalogowano jako ${userName}`, {
      theme: 'colored'
    });
    navigate('/', { replace: true });
  };

  return (
    <Row
      {...rest}
      as={Form}
      className={classNames('gx-2 mt-4', {
        'mx-sm-4 mb-2': type === 'simple'
      })}
      onSubmit={handleSubmit}
    >
      <Col>
        <Form.Control
          placeholder="Podaj swoje hasło"
          value={password}
          name="password"
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </Col>
      <Col xs={'auto'}>
        <Button
          variant="primary"
          type="submit"
          disabled={!password}
          className={classNames({ 'w-100': type !== 'simple' })}
        >
          Zaloguj się
        </Button>
      </Col>
    </Row>
  );
};

LockScreenForm.propTypes = {
  type: PropTypes.oneOf(['simple', 'split', 'card']),
  userName: PropTypes.string
};

export default LockScreenForm;

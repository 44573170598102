import translations from '../data/translations';

function useTranslation(errorString, language) {
  const translation = Object.entries(translations[language]).find(
    ([key]) => key === errorString
  );

  if (translation) {
    return translation[1];
  } else {
    return translations['en'][errorString] || errorString;
  }
}

export default useTranslation;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Button, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const TaskBanner = ({ title, status, isLoading, onSubmit }) => {
  const { id } = useParams();
  const handleSaveDraft = () => {
    onSubmit('draft');
  };

  const handlePublish = () => {
    onSubmit('pending');
  };

  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">{title}</h5>
          </Col>
          <Col xs="auto">
            {(!id || status === 'draft') && (
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                type="submit"
                onClick={handleSaveDraft}
                disabled={isLoading}
              >
                Zapisz szkic
              </Button>
            )}
            <Button
              size="sm"
              variant="falcon-primary"
              type="submit"
              onClick={handlePublish}
              disabled={isLoading}
            >
              {id && status !== 'draft' ? 'Zapisz' : 'Opublikuj'}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

TaskBanner.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default TaskBanner;

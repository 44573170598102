import PropTypes from 'prop-types';

export const userPropTypes = PropTypes.shape({
  _id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.shape({
    name: PropTypes.string,
    img: PropTypes.string
  }),
  position: PropTypes.string,
  address: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  recentActivity: PropTypes.string
});

import React, { useState, useContext } from 'react';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import PageHeader from 'components/common/PageHeader';
import MultiSelect from 'components/common/MultiSelect';
import Flex from 'components/common/Flex';

import axios from 'axios';
import { toast } from 'react-toastify';

import useTranslation from 'hooks/useTranslation';
import AuthContext from 'context/auth/AuthContext';

const HelpForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);

  const defaultValues = {
    name: '',
    email: '',
    topic: '',
    files: [],
    description: ''
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  const submittedValues = {};

  const onSubmit = async data => {
    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/help`, data);
      toast.success('Zgłoszenie wysłane pomyślnie');

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = '';
      }
      const allValues = { ...submittedValues, ...defaultValues };
      reset({ ...allValues });
    } catch (error) {
      console.error('Error creating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!authCtx.user) {
    return null;
  }
  return (
    <>
      <PageHeader
        title="Formularz kontaktowy"
        description="Wypełnij poniższy formularz, aby skontaktować się z nami."
        className="mb-3"
      />
      <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
        <Row className="g-3">
          <Col>
            <Card className="mb-3">
              <Card.Header as="h5">Szczegóły zgłoszenia</Card.Header>
              <Card.Body className="bg-light">
                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Tytuł zgłoszenia</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    isInvalid={!!errors.title}
                    {...register('title', {
                      required: 'Tytuł zgłoszenia jest wymagany'
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title && errors.title.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Flex className="flex-between-center">
                    <Form.Label>Typ zgłoszenia</Form.Label>
                  </Flex>
                  <Controller
                    name="type"
                    rules={{ required: 'To pole jest wymagane' }}
                    render={({ ref, field }) => (
                      <MultiSelect
                        {...field}
                        ref={ref}
                        closeMenuOnSelect={true}
                        isMulti={false}
                        options={[
                          {
                            value: 'bug',
                            label: 'Błąd aplikacji'
                          },
                          {
                            value: 'feature',
                            label: 'Propozycja nowej funkcjonalności'
                          },
                          {
                            value: 'other',
                            label: 'Inne'
                          }
                        ]}
                        placeholder=""
                        isInvalid={!!errors.type}
                      />
                    )}
                    control={control}
                  />
                  {errors.type && (
                    <Form.Text className="text-danger">
                      {errors.type.message}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Flex className="flex-between-center">
                    <Form.Label>Priorytet złoszenia</Form.Label>
                  </Flex>
                  <Controller
                    name="priority"
                    rules={{ required: 'To pole jest wymagane' }}
                    render={({ ref, field }) => (
                      <MultiSelect
                        {...field}
                        ref={ref}
                        defaultValue={{
                          value: '5',
                          label: 'Normalne'
                        }}
                        closeMenuOnSelect={true}
                        isMulti={false}
                        options={[
                          {
                            value: '5',
                            label: 'Normalne'
                          },
                          {
                            value: '9',
                            label: 'Pilne'
                          }
                        ]}
                        placeholder=""
                        isInvalid={!!errors.priority}
                      />
                    )}
                    control={control}
                  />
                  {errors.priority && (
                    <Form.Text className="text-danger">
                      {errors.priority.message}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Opis zgłoszenia</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    name="description"
                    {...register('description')}
                  />
                </Form.Group>

                <Button
                  size="md"
                  variant="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Wyślij
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default HelpForm;

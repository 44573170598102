import { useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';

const EquipmentOtherInfo = ({ register, control }) => {
  const [manufacturersList, setManufacturersList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [showManufacturerModal, setShowManufacturerModal] = useState(false);
  const [manufacturerName, setManufacturerName] = useState('');

  const handleShowManufacturerModal = () => setShowManufacturerModal(true);
  const handleShowCategoryModal = () => setShowCategoryModal(true);

  const handleCloseModal = () => {
    setShowCategoryModal(false);
    setShowManufacturerModal(false);
  };

  const handleSubmitCategory = async () => {
    if (!categoryName)
      return toast.error('Nazwa kategorii jest wymagana', { theme: 'colored' });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/equipment/categories/add`,
        {
          name: categoryName
        }
      );
      setCategoryName('');
      handleCloseModal();
      handleCategoriesAdded(response.data.category);
      toast.success('Dodano kategorię urządzenia', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error('Nie udało się dodać kategorii urządzenia', {
        theme: 'colored'
      });
    }
  };

  const handleSubmitManufacturer = async () => {
    if (!manufacturerName)
      return toast.error('Nazwa producenta jest wymagana', {
        theme: 'colored'
      });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/equipment/manufacturers/add`,
        {
          name: manufacturerName
        }
      );
      setManufacturerName('');
      handleCloseModal();
      handleManufacturersAdded(response.data.data);
      toast.success('Dodano producenta urządzenia', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error('Nie udało się dodać producenta urządzenia', {
        theme: 'colored'
      });
    }
  };

  const handleCategoriesAdded = addedCategories => {
    setCategoriesList([
      { value: addedCategories._id, label: addedCategories.name },
      ...categoriesList
    ]);
  };

  const handleManufacturersAdded = addedManufacturers => {
    setManufacturersList([
      { value: addedManufacturers._id, label: addedManufacturers.name },
      ...manufacturersList
    ]);
  };

  const getManufacturersList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment/manufacturers`
      );
      const manufacturers = response.data.data.map(manufacturer => ({
        value: manufacturer._id,
        label: manufacturer.name
      }));
      setManufacturersList(manufacturers);
    } catch (error) {
      console.error(error);
      toast.error('Błąd podczas pobierania listy urządzeń');
    }
  };

  const getCategoriesList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment/categories`
      );
      const categories = response.data.categories.map(category => ({
        value: category._id,
        label: category.name
      }));
      setCategoriesList(categories);
    } catch (error) {
      console.error(error);
      toast.error('Błąd podczas pobierania listy urządzeń');
    }
  };

  useEffect(() => {
    getManufacturersList();
    getCategoriesList();
  }, []);
  return (
    <>
      <Card>
        <Card.Header as="h5">Pozostałe informacje</Card.Header>
        <Card.Body className="bg-light">
          <Form.Group className="mb-3">
            <Flex className="flex-between-center">
              <Form.Label>Producent</Form.Label>
              <Button
                size="sm"
                variant="link"
                className="p-0"
                onClick={handleShowManufacturerModal}
              >
                Dodaj nowego producenta
              </Button>
            </Flex>
            <Controller
              name="manufacturer"
              render={({ ref, field }) => (
                <MultiSelect
                  {...field}
                  ref={ref}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={manufacturersList}
                  placeholder="Wybierz producenta..."
                />
              )}
              control={control}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Flex alignItems="center" justifyContent="between">
              <Form.Label>Kategorie</Form.Label>
              <Button
                size="sm"
                variant="link"
                className="p-0"
                onClick={handleShowCategoryModal}
              >
                Dodaj nową kategorię
              </Button>
            </Flex>
            <Controller
              name="categories"
              render={({ field, ref }) => (
                <MultiSelect
                  {...field}
                  ref={ref}
                  closeMenuOnSelect={false}
                  isMulti
                  options={categoriesList}
                  placeholder="Wybierz kategorię..."
                />
              )}
              control={control}
            />
          </Form.Group>

          <div className="border-dashed border-bottom my-3"></div>

          <Form.Group className="mb-3">
            <Form.Label>Urządzenie ma awarię?</Form.Label>
            <div className="d-flex gap-2">
              <Form.Check
                id="malfunction"
                type="switch"
                name="malfunction"
                {...register(`malfunction`)}
              />
            </div>
          </Form.Group>

          <div className="border-dashed border-bottom my-3"></div>

          <h6>Magazyn</h6>

          <Form.Check id="internal">
            <Form.Check.Input
              type="radio"
              value="internal"
              defaultChecked
              {...register(`storageType`)}
            />
            <Form.Check.Label className="mb-0 form-label fw-semi-bold">
              Romgos
            </Form.Check.Label>
          </Form.Check>
          <Form.Check id="external">
            <Form.Check.Input
              type="radio"
              value="external"
              {...register(`storageType`)}
            />
            <Form.Check.Label className="mb-0 form-label fw-semi-bold">
              Zewnętrzny
            </Form.Check.Label>
          </Form.Check>
        </Card.Body>
      </Card>

      <Modal show={showCategoryModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dodaj kategorię</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="categoryName">
              <Form.Label>Nazwa kategorii</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź nazwę kategorii"
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleSubmitCategory}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showManufacturerModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dodaj producenta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="manufacturerName">
              <Form.Label>Nazwa producenta</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź nazwę producenta"
                value={manufacturerName}
                onChange={e => setManufacturerName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleSubmitManufacturer}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
EquipmentOtherInfo.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired
};

export default EquipmentOtherInfo;

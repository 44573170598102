import { useMemo } from 'react';

const useFormatDate = (date, useLongDate = true) => {
  const formattedDate = useMemo(() => {
    const dateOptions = {
      weekday: useLongDate ? 'long' : undefined,
      year: 'numeric',
      month: useLongDate ? 'long' : 'numeric',
      day: '2-digit',
      timeZone: 'Europe/Warsaw'
    };
    return new Date(date).toLocaleDateString('pl-PL', dateOptions);
  }, [date]);

  const formattedTime = useMemo(() => {
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Europe/Warsaw'
    };
    return new Date(date).toLocaleTimeString('pl-PL', timeOptions);
  }, [date]);

  return [formattedDate, formattedTime];
};

export default useFormatDate;

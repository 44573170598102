import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import LeafletMap from './LeafletMap';

const ProjectLocation = ({ data }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Lokalizacje aktywnych zadań"
        titleTag="h5"
        light={true}
      />
      <Card.Body className="h-100 p-0" dir="ltr">
        <LeafletMap
          data={data}
          className="h-100 bg-white"
          style={{ minHeight: '300px' }}
        />
      </Card.Body>
      <Card.Footer className="bg-light">
        <Row className="justify-content-between">
          {/* TODO: Implement location overview filters */}
          {/* <Col xs="auto">
            <Form.Select size="sm" defaultValue="week">
              <option value="week">Aktywne zadania</option>
              <option value="month">Ostatni tydzień</option>
              <option value="year">Ostanti miesiąc</option>
            </Form.Select>
          </Col> */}
          {/* TODO: Implement location overview page */}
          {/* <Col xs="auto">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="chevron-right"
              iconClassName="ms-1 fs--1"
              iconAlign="right"
            >
              <span className="d-none d-sm-inline-block">
                Location overview
              </span>
            </IconButton>
          </Col> */}
        </Row>
      </Card.Footer>
    </Card>
  );
};

ProjectLocation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

export default ProjectLocation;

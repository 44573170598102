import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import ActivityContext from './ActivityContext';
import AuthContext from 'context/auth/AuthContext';

const ActivityState = props => {
  const initialState = [];
  const [activities, setActivities] = useState(initialState);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (!authContext.isAuthenticated) {
      return;
    }

    loadActivities();
    const interval = setInterval(() => {
      loadActivities();
    }, 10 * 60 * 1000);

    return () => clearInterval(interval);
  }, [authContext.isAuthenticated]);

  /**
   * Load activities
   * @returns {void}
   */
  const loadActivities = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/userActions/?limit=15`
      );
      setActivities(response.data.userActions);
    } catch (error) {
      console.error(error.response?.data.message);
    }
  };

  return (
    <ActivityContext.Provider
      value={{
        activities
      }}
    >
      {props.children}
    </ActivityContext.Provider>
  );
};

ActivityState.propTypes = {
  children: PropTypes.node
};

export default ActivityState;

import React from 'react';
import ForgotPasswordForm from 'components/authentication/ForgotPasswordForm';
import bgVideo from 'assets/video/romgos_intro_final.mp4';
import AuthSplitLayout from 'layouts/AuthSplitLayout';

const ForgetPassword = () => {
  return (
    <AuthSplitLayout bgProps={{ video: [bgVideo], position: '50% 20%' }}>
      <div className="text-center">
        <h4 className="mb-0">Zapomniałeś hasła?</h4>
        <small>
          Wpisz swój adres e-mail, a wyślemy Ci link do resetowania.
        </small>
        <ForgotPasswordForm layout="split" />
      </div>
    </AuthSplitLayout>
  );
};

export default ForgetPassword;

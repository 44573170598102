import { setItemToStore, removeItemFromStore } from 'helpers/utils';

export const authReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'LOAD_USER_REQUEST':
      return {
        ...state,
        isLoading: true
      };
    case 'LOAD_USER_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: payload
      };
    case 'LOGIN_SUCCESS':
      setItemToStore('token', payload.token);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        token: payload.token
      };
    case 'LOGOUT':
      removeItemFromStore('token');
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        user: null
      };
    case 'AUTH_ERROR':
      // todo: figure out if needed
      // removeItemFromStore('token');
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        user: null
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

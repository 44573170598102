import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import plLocale from '@fullcalendar/core/locales/pl';
import CalendarTaskModal from './CalendarTaskModal';
import DropdownFilter from 'components/common/DropdownFilter';
import AppContext from 'context/Context';
import useTranslation from 'hooks/useTranslation';
import { toast } from 'react-toastify';
import axios from 'axios';

export const formatDate = (date, time) => {
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };

  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  let formattedDate, formattedTime;

  if (date) {
    formattedDate = new Date(date)
      .toLocaleDateString('en-US', dateOptions)
      .split('/')
      .reverse()
      .join('-')
      .replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$3-$2');

    if (!time) return formattedDate;
  }

  if (time) {
    formattedTime = new Date(time).toLocaleTimeString('en-US', timeOptions);

    if (!date) return formattedTime;
  }

  return `${formattedDate} ${formattedTime}`;
};

const Calendar = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState('Miesiąc');
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);
  const [modalTaskContent, setModalTaskContent] = useState({});
  const [tasks, setTasks] = useState([]);

  const formatTask = useMemo(() => {
    return events => {
      return events.map(event => {
        const {
          name,
          startDate,
          startTime,
          endDate,
          endTime,
          description,
          location,
          status
        } = event;
        const start = formatDate(startDate, startTime);
        const end = formatDate(endDate, endTime);
        const title = name || '';
        const formattedLocation = location.formattedAddress || '';

        let className = '';

        switch (status) {
          case 'completed':
            className = 'bg-soft-success';
            break;
          case 'active':
            className = 'bg-soft-primary';
            break;
          case 'onhold':
          case 'archived':
            className = 'bg-soft-warning';
            break;
          case 'pending':
          case 'draft':
            className = 'bg-soft-secondary';
            break;
          default:
            className = '';
            break;
        }

        return {
          id: event._id,
          title,
          start,
          end,
          description,
          className,
          location: formattedLocation
        };
      });
    };
  }, [tasks]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks`
      );
      setTasks(formatTask(response.data.tasks));
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const taskTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: false
  };

  const handleEventClick = info => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      setModalTaskContent(info);
      setIsOpenTaskModal(true);
    }
  };

  const viewName = ['Miesiąc', 'Tydzień', 'Dzień', 'Lista'];

  const handleFilter = filter => {
    setCurrentFilter(filter);
    switch (filter) {
      case 'Miesiąc':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Tydzień':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Dzień':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
    }
  };

  useEffect(() => {
    fetchData();
    setCalendarApi(calendarRef.current.getApi());
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="nextTooltip">Poprzedni</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="previousTooltip">Następny</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Dziś
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col xs="auto" className="d-flex order-md-0"></Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={2}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={false}
            eventTimeFormat={taskTimeFormat}
            eventClick={handleEventClick}
            events={tasks}
            locale={plLocale}
          />
        </Card.Body>
      </Card>

      <CalendarTaskModal
        isOpenTaskModal={isOpenTaskModal}
        setIsOpenTaskModal={setIsOpenTaskModal}
        modalTaskContent={modalTaskContent}
      />
    </>
  );
};

export default Calendar;

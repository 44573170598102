import React from 'react';
import PasswordResetForm from 'components/authentication/PasswordResetForm';
import bgVideo from 'assets/video/romgos_intro_final.mp4';
import AuthSplitLayout from 'layouts/AuthSplitLayout';

const PasswordReset = () => {
  return (
    <AuthSplitLayout bgProps={{ video: [bgVideo], position: '50% 20%' }}>
      <div className="text-center">
        <h3>Resetuj hasło</h3>
      </div>
      <PasswordResetForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default PasswordReset;

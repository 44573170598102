import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    try {
      setIsLoading(true);
      axios.post(`${process.env.REACT_APP_API_URL}/auth/password/reset`, {
        email
      });
      toast.success(
        'Link do resetowania hasła został wysłany na podany adres email.'
      );
      navigate('/login', { replace: true });
    } catch (err) {
      toast.error('Nie udało się wysłać linku do resetowania hasła.', {});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Adres email'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email || isLoading}>
          Wyślij link do resetowania hasła
        </Button>
      </Form.Group>

      <Link
        className="fs--1 text-600"
        to="mailto:support@mostlydigital.com?subject=Problemy z odzyskaniem hasła."
      >
        Nie mogę odzyskać konta za pomocą tej strony
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgotPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgotPasswordForm.defaultProps = { layout: 'simple' };

export default ForgotPasswordForm;

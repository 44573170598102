import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  buildActivityObject,
  createActivityLinkMarkup,
  EXCLUDED_ACTIVITY_TYPES
} from 'helpers/activity-utils';

import useFormatDate from 'hooks/useFormatDate';

const Notification = ({ activity, className, unread, flush }) => {
  const [formattedDate, formattedTime] = useFormatDate(
    activity?.createdAt,
    false
  );

  if (!activity || EXCLUDED_ACTIVITY_TYPES.includes(activity.item.type))
    return null;

  const activityObject = buildActivityObject(activity);

  return (
    <div
      className={classNames(
        'notification',
        { 'notification-unread': unread, 'notification-flush': flush },
        className
      )}
    >
      <div className="notification-body">
        <p className="mb-1">{createActivityLinkMarkup(activityObject)}</p>
        <span className="notification-time">
          {' '}
          {`${formattedDate} ${formattedTime}`}
        </span>
      </div>
    </div>
  );
};

Notification.propTypes = {
  activity: PropTypes.object,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;

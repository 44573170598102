import React from 'react';
import { PropTypes } from 'prop-types';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import Lottie from 'lottie-react';
import warningLight from 'assets/img/animated-icons/warning-light.json';

const EquipmentDetailHeader = ({ equipment }) => {
  const navigate = useNavigate();

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      'Jesteś pewien że chcesz usunąć to urządzenie? Tej operacji nie można cofnąć. Czy chcesz kontynuować?'
    );
    if (confirmDelete) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/equipment/${_id}`
        );
        navigate('/equipment-list');
        toast.success(useTranslation(response.data.message, 'pl'), {
          theme: 'colored'
        });
      } catch (error) {
        console.error(error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      }
    }
  };

  if (!equipment)
    return (
      <Card className="p-0 mb-3">
        <Card.Body className="overflow-hidden">
          <Row className="flex-center">
            <Col>
              <Flex>
                <div className="fs--1 ms-2 flex-1">
                  <h5 className="fs-0 text-capitalize"></h5>
                  <p className="mb-0 text-capitalize"></p>
                </div>
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );

  const { name, _id, malfunction } = equipment;

  return (
    <Card className="p-0 mb-3">
      <Card.Body className="overflow-hidden">
        <Row className="flex-center">
          <Col>
            <Flex>
              <div className="fs--1 ms-2 flex-1">
                <h5
                  className={`d-flex fs-0 text-capitalize ${
                    malfunction && 'text-warning'
                  }`}
                >
                  {malfunction && (
                    <Lottie
                      animationData={warningLight}
                      style={{
                        height: '32px',
                        width: '32px',
                        margin: '-7px 0'
                      }}
                    />
                  )}
                  {name}
                </h5>
                <p className="mb-0 text-capitalize">#{_id}</p>
              </div>
            </Flex>
          </Col>
          <Col md="auto" className="mt-4 mt-md-0">
            <Flex>
              <Button
                variant="falcon-default"
                size="sm"
                className="me-2"
                as={Link}
                to={`/create-task?equipmentID=${_id}&equipmentName=${name}`}
              >
                Stwórz zadanie
              </Button>
              <Button
                className="me-2"
                variant="falcon-default"
                size="sm"
                icon="share-alt"
                as={Link}
                to={`/create-equipment/${_id}`}
              >
                Edytuj
              </Button>
              <Button
                variant="falcon-danger"
                size="sm"
                onClick={handleDelete}
                className="px-4 px-sm-5"
              >
                Usuń
              </Button>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EquipmentDetailHeader.propTypes = {
  equipment: PropTypes.object
};

export default EquipmentDetailHeader;

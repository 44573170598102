import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, ProgressBar } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import FalconLink from 'components/common/FalconLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { userPropTypes } from 'shapes/userPropTypes';
import useFormatDate from 'hooks/useFormatDate';

const columns = [
  {
    accessor: 'name',
    Header: 'Nazwa i lokalizacja zdania',
    Cell: rowData => {
      const { _id, name, address } = rowData.row.original;
      return (
        <Flex alignItems="center" className="position-relative">
          <div className="flex-1">
            <h6 className="mb-0 fw-semi-bold">
              <Link className="text-dark stretched-link" to={`/task/${_id}`}>
                {name}
              </Link>
            </h6>
            <p className="fs--2 mb-0 text-500">{address}</p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'tasks',
    Header: 'Wykonane zadania',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      const { tasks, status } = rowData.row.original;
      const totalTasks = tasks.length;
      const completedTasks = tasks.filter(task => task.completed).length;
      const progress =
        totalTasks !== 0 ? (completedTasks / totalTasks) * 100 : 0;
      return (
        <ProgressBar
          now={progress}
          style={{ height: 5 }}
          className="rounded-pill align-middle"
          variant={
            status === 'completed'
              ? 'success'
              : status === 'archived'
              ? 'warning'
              : 'info'
          }
        />
      );
    }
  },
  {
    accessor: 'duration',
    Header: 'Postęp',
    cellProps: {
      className: 'text-center fw-semi-bold fs--1'
    },
    headerProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      const { logs } = rowData.row.original;
      const totalDuration = logs.reduce((acc, { time }) => acc + time, 0);
      const hours = Math.floor(totalDuration / 60);
      const minutes = totalDuration % 60;

      return (
        <p className="mb-0">
          {hours}g {minutes}m
        </p>
      );
    }
  },
  {
    accessor: 'endDate',
    Header: 'Data zakończenia',
    cellProps: {
      className: 'text-center fw-semi-bold fs--1'
    },
    headerProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      const { endDate } = rowData.row.original;
      const [formattedDate] = useFormatDate(endDate, false);
      return <p className="mb-0">{formattedDate}</p>;
    }
  },
  {
    accessor: 'users',
    Header: 'Użytkownicy',
    disableSortBy: true,
    headerProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      return (
        <AvatarGroup className="justify-content-end">
          {rowData.row.original.users.map(({ avatar, name }, index) => {
            return avatar.img ? (
              <Avatar
                key={index}
                size="xl"
                src={avatar.img}
                width="60"
                alt={name}
              />
            ) : (
              <Avatar key={index} size="xl" name={avatar.name} />
            );
          })}
        </AvatarGroup>
      );
    }
  }
];

const RunningTasks = ({ tasks, title = 'Aktualne zadania' }) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={tasks}
      sortable
      pagination
      perPage={10}
    >
      <Card>
        <FalconCardHeader title={title} titleTag="h6" />
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-800 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              borderless: true,
              className: 'fs--2 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer className="bg-light py-2">
          <Row className="g-0 flex-between-center">
            <Col xs="auto" />
            <Col xs="auto">
              <FalconLink
                to="/task-list"
                title="Wszystkie zadania"
                className="px-0"
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

RunningTasks.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      progress: PropTypes.number,
      duration: PropTypes.number,
      name: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        name: PropTypes.string,
        size: PropTypes.string
      }),
      projectName: PropTypes.string,
      users: PropTypes.arrayOf(userPropTypes),
      endDate: PropTypes.string.isRequired,
      isLast: PropTypes.bool
    })
  ),
  title: PropTypes.string
};

export default RunningTasks;

import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { handleMultiple } from '../../../../helpers/bulkAction';
import { ChecklistFilters } from './ChecklistFilters';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import React from 'react';

const ChecklistTableHeader = ({
  selectedRowIds,
  checklists,
  setChecklists,
  setQueryParams
}) => {
  const [selectedAction, setSelectedAction] = useState('');
  const [open, setOpen] = useState(false);

  const csvLink = useRef();

  const selectedItems = Object.keys(selectedRowIds).map(id => {
    const task = checklists.find(item => item._id === id);
    return {
      ...task,
      ...{
        createdBy: task.createdBy.firstName + ' ' + task.createdBy.lastName,
        createdAt: dayjs(task.createdAt).format('DD-MM-YYYY HH:mm'),
        categories: task.categories.map(category => category.name).join(', ')
      }
    };
  });

  const headers = [
    { label: 'Nazwa', key: 'name' },
    {
      label: 'Autor',
      key: `createdBy`
    },
    {
      label: 'Data utworzenia',
      key: 'createdAt'
    },
    {
      label: 'Kategorie',
      key: 'categories'
    }
  ];

  const handleApplyAction = () => {
    switch (selectedAction) {
      case 'delete':
        handleMultiple(
          selectedRowIds,
          'tasks/checklists',
          checklists,
          setChecklists,
          'delete'
        );
        break;
      case 'export':
        csvLink.current.link.click();
        break;
    }
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Lista zadań</h5>
      </Col>
      <Col
        xs={8}
        sm="auto"
        className="ms-auto text-end ps-0 d-flex justify-content-end"
      >
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-3"
          className="mx-2"
          onClick={() => setOpen(!open)}
        >
          <span>Filtruj</span>
        </IconButton>
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Działania masowe"
              value={selectedAction}
              onChange={e => setSelectedAction(e.target.value)}
            >
              <option value="">Działania masowe</option>
              <option value="delete">Usuń</option>
              <option value="export">Eksportuj</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleApplyAction}
              disabled={!selectedAction}
            >
              Zastosuj
            </Button>
            <CSVLink
              data={selectedItems}
              headers={headers}
              filename={`checklisty_${dayjs().format('DD-MM-YYYY_HH-mm')}.csv`}
              className="hidden"
              ref={csvLink}
              target="_blank"
            />
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              as={Link}
              to="/create-checklist/"
            >
              <span className="d-none d-sm-inline-block ms-1">
                Nowa Checklista
              </span>
            </IconButton>
          </div>
        )}
      </Col>
      <Col xs={12} className="mt-2">
        <ChecklistFilters open={open} setQueryParams={setQueryParams} />
      </Col>
    </Row>
  );
};

ChecklistTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  checklists: PropTypes.array,
  setChecklists: PropTypes.func,
  setQueryParams: PropTypes.func
};

export default ChecklistTableHeader;

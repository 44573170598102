import React from 'react';
import { PropTypes } from 'prop-types';
import Tasks from 'components/common/Tasks';
import SharedFiles from 'components/common/SharedFiles';
import { Card } from 'react-bootstrap';

const EquipmentDetailAside = ({ equipment, relatedTasks }) => {
  const { _id, manufacturer, uploadedFiles } = equipment;
  return (
    <div className="sticky-sidebar">
      <Card className="mb-3 fs--1">
        <Card.Body>
          <h6>Producent</h6>
          <p className="mb-1">{manufacturer.name}</p>
          <h6 className="mt-4">Magazyn</h6>
          <p className="mb-1">
            {manufacturer.storageType === 'romgos' ? 'Romgos' : 'Zewnętrzny'}
          </p>
        </Card.Body>
      </Card>
      {uploadedFiles.length > 0 && (
        <SharedFiles
          className="mb-3"
          files={uploadedFiles}
          parent={{ _id, type: 'equipment' }}
        />
      )}
      <Tasks cardTitle="Aktualne zadania" tasks={relatedTasks} />
    </div>
  );
};

EquipmentDetailAside.propTypes = {
  equipment: PropTypes.object.isRequired,
  relatedTasks: PropTypes.array.isRequired
};

export default EquipmentDetailAside;

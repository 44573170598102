import React from 'react';
import PropTypes from 'prop-types';
import { taskPropTypes } from 'shapes/taskPropTypes';
import useFormatDate from 'hooks/useFormatDate';
import Tasks from 'components/common/Tasks';
import SharedFiles from 'components/common/SharedFiles';
import { Row, Card, Placeholder, ProgressBar } from 'react-bootstrap';
import StatusBadge from 'components/common/StatusBadge';

import { getReactionTime } from '../../../../helpers/getReactionTime';
import { getTotalWorkTime } from '../../../../helpers/getTotalWorkTime';

const TaskDetailAside = ({ task, relatedTasks }) => {
  const {
    _id,
    startDate,
    endDate,
    locationName,
    streetName,
    city,
    zip,
    uploadedFiles,
    stockItems,
    status,
    tasks,
    logs,
    createdAt,
    finishedAt,
    reportFile,
    reporterName,
    reporterPhone
  } = task;
  const [formattedStartDate, formattedStartTime] = useFormatDate(startDate);
  const [formattedEndDate, formattedEndTime] = useFormatDate(endDate);
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(task => task.completed).length;
  const progress = totalTasks !== 0 ? (completedTasks / totalTasks) * 100 : 0;

  const reactionTime = getReactionTime(createdAt, finishedAt);
  const totalWorkTime = getTotalWorkTime(logs);

  if (!task) {
    return (
      <Card className="mb-3 fs--1">
        <Card.Body>
          <Placeholder as={Row} animation="glow" />
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className="sticky-sidebar">
      <Card className="mb-3 fs--1">
        <Card.Body>
          <h6>Status</h6>
          <div className="mb-1 fs-1">
            <StatusBadge status={status} />
          </div>
          <h6 className="mt-4">
            Zrealizowane zadania{' '}
            <small>
              ({completedTasks}/{totalTasks})
            </small>
          </h6>
          <div className="mb-1">
            <ProgressBar
              className="mb-2"
              striped
              variant="info"
              now={progress}
              label={`${Math.round(progress)}%`}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <p className="my-0">Czas pracy: {totalWorkTime}</p>
              {(status === 'completed' || status === 'archived') &&
                reactionTime && (
                  <p className="my-0">Czas reakcji: {reactionTime}</p>
                )}
            </div>
            {(status === 'completed' || status === 'archived') &&
              reportFile.url && (
                <div className="col-6 d-flex justify-content-end align-items-end">
                  <a
                    href={`${process.env.REACT_APP_FILE_HOST}${reportFile.url}`}
                    className="btn btn-sm btn-outline-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pobierz raport
                  </a>
                </div>
              )}
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-3 fs--1">
        <Card.Body>
          <h6>Osoba zgłaszająca</h6>
          {reporterName ? (
            <p className="mb-1">
              {reporterName} {reporterPhone && ` - ${reporterPhone}`}
            </p>
          ) : (
            <p className="mb-1">Brak danych</p>
          )}
          <h6 className="mt-4">Data i czas realizacji</h6>
          <p className="mb-1">
            od: {formattedStartDate}, {formattedStartTime} <br />
            do: {formattedEndDate}, {formattedEndTime}
          </p>
          <h6 className="mt-4">Miejsce realizacji</h6>
          <div className="mb-1">
            {locationName} <br />
            {streetName} <br />
            {zip} {city} <br />
          </div>
          {stockItems && stockItems.length > 0 && (
            <>
              <h6 className="mt-4">Elementy z magazynu</h6>
              <ul className="ps-3">
                {stockItems.map((item, index) => (
                  <li key={index}>
                    {item.count} x {item.name}{' '}
                    {item.checked && <small>(do zwrotu)</small>}
                  </li>
                ))}
              </ul>
            </>
          )}
        </Card.Body>
      </Card>
      {task.updates && task.updates.length > 0 && (
        <Card className="mb-3 fs--1">
          <Card.Body>
            <h6>Aktualizacje zadania</h6>
            <div>
              {task.updates.map((item, index) => {
                return <UpdateItem item={item} key={index} />;
              })}
            </div>
          </Card.Body>
        </Card>
      )}
      {uploadedFiles.length > 0 && (
        <SharedFiles
          className="mb-3"
          files={uploadedFiles}
          parent={{ _id, type: 'tasks' }}
        />
      )}
      <Tasks cardTitle="Powiązane zadania" tasks={relatedTasks} />
    </div>
  );
};

const UpdateItem = ({ item }) => {
  if (!item) return null;

  const [date, time] = useFormatDate(item.createdAt, item.createdAt);
  return (
    <Card className="mb-2">
      <Card.Body>
        <Card.Subtitle className="mb-2">{`${date} ${time}`}</Card.Subtitle>
        <Card.Subtitle className="mb-2 text-muted">
          {item.createdBy &&
            `${item.createdBy.firstName} ${item.createdBy.lastName}`}
        </Card.Subtitle>
        <Card.Text>{item.description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const UpdateItemProps = (UpdateItem.propTypes = {
  item: PropTypes.shape({
    createdAt: PropTypes.string,
    createdBy: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string
    }),
    description: PropTypes.string
  })
});

TaskDetailAside.propTypes = {
  task: taskPropTypes.isRequired,
  relatedTasks: PropTypes.arrayOf(taskPropTypes),
  taskUpdates: PropTypes.arrayOf(UpdateItemProps)
};

export default TaskDetailAside;

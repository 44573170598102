import React, { useState } from 'react';
import axios from 'axios';
import FalconCardHeader from 'components/common/FalconCardHeader';
import useTranslation from 'hooks/useTranslation';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({ defaultValues, mode: 'onChange' });

  const newPasswordValue = watch('newPassword');

  const onSubmit = async data => {
    try {
      setIsLoading(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/users/password/update`,
        data
      );
      toast.success('Hasło zostało zmienione', { theme: 'colored' });
      reset({ ...defaultValues });
    } catch (error) {
      toast.error(useTranslation(error.response.data.message, 'pl'), {
        theme: 'colored'
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Zmiana hasła" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="currentPassword">
            <Form.Label>Obecne hasło</Form.Label>
            <Form.Control
              type="password"
              name="currentPassword"
              autoComplete="current-password"
              isInvalid={!!errors.currentPassword}
              {...register('currentPassword', {
                required: 'Obecne hasło jest wymagane'
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.currentPassword && errors.currentPassword.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>Nowe hasło</Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              autoComplete="new-password"
              isInvalid={!!errors.newPassword}
              {...register('newPassword', {
                required: 'Nowe hasło jest wymagane'
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.newPassword && errors.newPassword.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Potwierdź nowe hasło</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              autoComplete="new-password"
              isInvalid={!!errors.confirmPassword}
              {...register('confirmPassword', {
                required: 'Potwierdzenie hasła jest wymagane',
                validate: value =>
                  value === newPasswordValue || 'Hasła muszą być takie same'
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword && errors.confirmPassword.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Button disabled={isLoading} className="w-100" type="submit">
            Zaktualizuj hasło
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;

import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { handleMultiple } from '../../../../helpers/bulkAction';
import { TaskFilters } from './TaskFilters';
import { CSVLink } from 'react-csv';
import { useRef } from 'react';
import dayjs from 'dayjs';
import { statusLabelsMap } from '../../../common/StatusBadge';
import { priorityMap } from '../../../common/PriorityBadge';

const TaskTableHeader = ({
  selectedRowIds,
  tasks,
  setTasks,
  setQueryParams
}) => {
  const [selectedAction, setSelectedAction] = useState('');
  const [open, setOpen] = useState(false);

  const csvLink = useRef();

  const selectedItems = Object.keys(selectedRowIds).map(id => {
    const task = tasks.find(item => item._id === id);
    return {
      ...task,
      ...{
        createdBy: task.createdBy.firstName + ' ' + task.createdBy.lastName,
        users: task.users
          .map(user => user.firstName + ' ' + user.lastName)
          .join(', '),
        startDate: dayjs(task.startDate).format('DD-MM-YYYY HH:mm'),
        endDate: dayjs(task.endDate).format('DD-MM-YYYY HH:mm'),
        status: statusLabelsMap[task.status],
        priority: priorityMap[task.priority]
      }
    };
  });

  const headers = [
    { label: 'Nazwa zadania', key: 'name' },
    { label: 'Autor', key: `createdBy` },
    { label: 'Data(od)', key: 'startDate' },
    { label: 'Data(do)', key: 'endDate' },
    { label: 'Pracownicy', key: 'users' },
    { label: 'Lokalizacja', key: 'location.formattedAddress' },
    { label: 'Status', key: 'status' },
    { label: 'Urządzenie', key: 'equipment.name' },
    { label: 'ID Urządzenia', key: 'equipment.equipmentId' },
    { label: 'Priorytet', key: 'priority' }
  ];

  const handleApplyAction = () => {
    switch (selectedAction) {
      case 'delete':
        handleMultiple(selectedRowIds, 'tasks', tasks, setTasks, 'delete');
        break;
      case 'archive':
        handleMultiple(selectedRowIds, 'tasks', tasks, setTasks, 'put', {
          status: 'archived'
        });
        break;
      case 'export':
        csvLink.current.link.click();
        break;
    }
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Lista zadań</h5>
      </Col>
      <Col
        xs={8}
        sm="auto"
        className="ms-auto text-end ps-0 d-flex justify-content-end"
      >
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-3"
          className="mx-2"
          onClick={() => setOpen(!open)}
        >
          <span>Filtruj</span>
        </IconButton>
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Działania masowe"
              value={selectedAction}
              onChange={e => setSelectedAction(e.target.value)}
            >
              <option value="">Działania masowe</option>
              <option value="delete">Usuń</option>
              <option value="archive">Archiwizuj</option>
              <option value="export">Eksportuj</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleApplyAction}
              disabled={!selectedAction}
            >
              Zastosuj
            </Button>
            <CSVLink
              data={selectedItems}
              headers={headers}
              filename={`zadania_${dayjs().format('DD-MM-YYYY_HH-mm')}.csv`}
              className="hidden"
              ref={csvLink}
              target="_blank"
            />
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              as={Link}
              to="/create-task/"
            >
              <span className="d-none d-sm-inline-block ms-1">
                Nowe zadanie
              </span>
            </IconButton>
          </div>
        )}
      </Col>
      <Col xs={12} className="mt-2">
        <TaskFilters open={open} setQueryParams={setQueryParams} />
      </Col>
    </Row>
  );
};

TaskTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  tasks: PropTypes.array,
  setTasks: PropTypes.func,
  setQueryParams: PropTypes.func
};

export default TaskTableHeader;

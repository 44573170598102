import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { taskPropTypes } from 'shapes/taskPropTypes';
import useFormatDate from 'hooks/useFormatDate';
import Flex from 'components/common/Flex';
import StatusBadge from 'components/common/StatusBadge';

const Task = ({ details, isLast }) => {
  const { _id, name, startDate, endDate, users, status, description } = details;
  const [formattedStartDate, formattedStartTime] = useFormatDate(startDate);
  const [formattedEndDate, formattedEndTime] = useFormatDate(endDate);
  return (
    <Flex>
      <div className="flex-1 position-relative">
        <h6 className="fs-0 mb-0 d-flex gap-2">
          <Link to={`/task/${_id}`}>
            <span className="me-1">{name}</span>
          </Link>
          {status && <StatusBadge status={status} size="sm" />}
        </h6>
        {users && users[0]._id && (
          <p className="mb-1">
            Przypisane do
            {users.map((user, index) => (
              <Link
                to={`/user/${user._id}`}
                className="text-700 ps-1"
                key={index}
              >
                {user.firstName} {user.lastName}
                {index !== users.length - 1 && ', '}
              </Link>
            ))}
          </p>
        )}
        {formattedStartDate && formattedStartTime && (
          <p className="text-1000 mb-0">
            Od: {formattedStartDate} {formattedStartTime}
          </p>
        )}
        {formattedEndDate && formattedEndTime && (
          <p className="text-1000 mb-0">
            Do: {formattedEndDate} {formattedEndTime}
          </p>
        )}

        {description && (
          <p className="mb-0 mt-3">
            {description.length > 200
              ? description.slice(0, 200) + '...'
              : description}
          </p>
        )}
        {!isLast && <div className="border-dashed border-bottom my-3"></div>}
      </div>
    </Flex>
  );
};

Task.propTypes = {
  details: taskPropTypes.isRequired,
  isLast: PropTypes.bool
};

export default Task;

/**
 * Handles multiple selection of tasks and performs the specified API action.
 *
 * @param {Object} selectedRowIds - The selected row IDs.
 * @param {string} apiEndpoint - The API endpoint to call.
 * @param {Array} items - The items to update.
 * @param {Function} setItems - The function to update the items.
 * @param {string} method - The HTTP method to use (e.g. 'delete', 'put').
 * @param {Object} [data] - The data to send with the API request (optional).
 * @returns {Function} - The async function that performs the API action.
 */

import { toast } from 'react-toastify';
import axios from 'axios';

const messagesList = [
  {
    endpoint: 'tasks',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te zadania?',
    success: successfulIds =>
      `Wybrane zadania (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania zadań',
    singleError: rejectedNames =>
      `Nie można usunąć zadań: ${rejectedNames.join(', ')}`
  },
  {
    endpoint: 'tasks',
    method: 'put',
    confirmation: () => 'Jesteś pewny, że chcesz zarchiwizować te zadania?',
    success: successfulIds =>
      `Wybrane zadania (${successfulIds.length}) zostały zarchiwizowane`,
    globalError: () => 'Błąd podczas archiwizacji zadań',
    singleError: rejectedNames =>
      `Nie można zarchiwizować zadań: ${rejectedNames.join(', ')}`
  },
  {
    endpoint: 'tasks/categories',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te kategorie?',
    success: successfulIds =>
      `Wybrane kategorie (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania kategorii',
    singleError: rejectedNames =>
      `Nie można usunąć kategorii: ${rejectedNames.join(', ')}`
  },
  {
    endpoint: 'tasks/locations',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te lokalizacje?',
    success: successfulIds =>
      `Wybrane lokalizacje (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania lokalizacje',
    singleError: rejectedNames =>
      `Nie można usunąć lokalizacji: ${rejectedNames.join(', ')}`
  },
  {
    endpoint: 'tasks/checklists',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te checklisty?',
    success: successfulIds =>
      `Wybrane checklisty (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania checklisty',
    singleError: rejectedNames =>
      `Nie można usunąć checklisty: ${rejectedNames.join(', ')}`
  },
  {
    endpoint: 'equipment/categories',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć te kategorie?',
    success: successfulIds =>
      `Wybrane kategorie (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania kategorii',
    singleError: rejectedNames =>
      `Nie można usunąć kategorii: ${rejectedNames.join(', ')}`
  },
  {
    endpoint: 'equipment',
    method: 'delete',
    confirmation: selectedIds =>
      `Jesteś pewny, że chcesz usunąć te urządzenia? (${selectedIds.length})`,
    success: successfulIds =>
      `Wybrane urządzenia (${successfulIds.length}) zostały usunięte`,
    globalError: () => 'Błąd podczas usuwania urządzeń',
    singleError: rejectedNames =>
      `Nie można usunąć urządzeń, które mają przypisane zadania: 
      ${rejectedNames.join(', ')}`
  },
  {
    endpoint: 'users',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć tych użytkowników?',
    success: successfulIds =>
      `Wybrani użytkownicy (${successfulIds.length}) zostali usunięci`,
    globalError: () => 'Błąd podczas usuwania użytkowników',
    singleError: rejectedNames =>
      `Nie można usunąć użytkowników: ${rejectedNames.join(', ')}`
  },
  {
    endpoint: 'equipment/manufacturers',
    method: 'delete',
    confirmation: () => 'Jesteś pewny, że chcesz usunąć tych producentów?',
    success: successfulIds =>
      `Wybrani producenci (${successfulIds.length}) zostali usunięci`,
    globalError: () => 'Błąd podczas usuwania producentów',
    singleError: rejectedNames =>
      `Nie można usunąć producentów przypisanych do urządzeń: 
      ${rejectedNames.join(', ')}`
  },
  {
    endpoint: 'users',
    method: 'put',
    confirmation: () =>
      'Jesteś pewny, że chcesz zarchiwizować tych użytkowników?',
    success: successfulIds =>
      `Wybrani użytkownicy (${successfulIds.length}) zostali zarchiwizowani`,
    globalError: () => 'Błąd podczas archiwizacji użytkowników',
    singleError: rejectedNames =>
      `Nie można zarchiwizować użytkowników: ${rejectedNames.join(', ')}`
  }
];

export const handleMultiple = async (
  selectedRowIds,
  apiEndpoint,
  items,
  setItems,
  method = 'delete',
  data
) => {
  const selectedIds = Object.keys(selectedRowIds);
  const messages = messagesList.find(
    message => message.endpoint === apiEndpoint && message.method === method
  );
  const confirmed = window.confirm(messages.confirmation(selectedIds));

  if (confirmed) {
    try {
      const promises = selectedIds.map(id => ({
        id,
        promise: axios[method](
          `${process.env.REACT_APP_API_URL}/${apiEndpoint}/${id}`,
          data
        )
      }));
      const results = await Promise.allSettled(
        promises.map(({ id, promise }) =>
          promise.then(() => ({ id, status: 'fulfilled' }))
        )
      );

      const successfulIds = results
        .filter(result => result.value?.status == 'fulfilled')
        .map(result => result.value.id);

      const rejectedIds = selectedIds.filter(id => !successfulIds.includes(id));

      const rejectedNames = items
        .filter(item => rejectedIds.includes(item._id))
        .map(
          item =>
            `${item.name}${item.equipmentId ? ` (${item.equipmentId})` : ''}`
        );

      if (rejectedNames.length > 0) {
        toast.error(messages.singleError(rejectedNames), {
          theme: 'colored'
        });
      }

      if (successfulIds.length > 0) {
        const successMessage = messages.success(successfulIds);
        toast.success(successMessage, {
          theme: 'colored'
        });

        setItems(items.filter(item => !successfulIds.includes(item._id)));
      }
    } catch (error) {
      console.error(error);
      toast.error(messages.globalError, { theme: 'colored' });
    }
  }
};

import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { userPropTypes } from 'shapes/userPropTypes';

const DangerZone = ({ user }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const isArchived = user?.status === 'archived';

  const handleArchive = async () => {
    if (userId) {
      const confirmed = window.confirm(
        isArchived
          ? 'Czy na pewno chcesz przywrócić tego użytkownika?'
          : 'Czy na pewno chcesz zarchiwizować tego użytkownika?'
      );
      if (confirmed) {
        try {
          await axios.put(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
            status: isArchived ? 'active' : 'archived'
          });
        } catch (error) {
          console.error('Failed to archive user', error);
          toast.error('Błąd podczas archiwizacji użytownika');
        } finally {
          toast.success('Zarchiwizowano użytownika');
          navigate('/user/user-list', { replace: true });
        }
      }
    }
  };

  const handleDelete = async () => {
    if (userId) {
      const confirmed = window.confirm(
        'Czy na pewno chcesz usunąć tego użytkownika? Tej operacji nie można cofnąć!'
      );
      if (confirmed) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/users/${userId}`
          );
        } catch (error) {
          console.error('Failed to delete user', error);
          toast.error('Błąd podczas usuwania użytkownika');
        } finally {
          navigate('/user/user-list', { replace: true });
        }
      }
    }
  };

  return (
    <Card>
      <FalconCardHeader title="Danger Zone" />
      <Card.Body className="bg-light">
        <h5 className="mb-0">
          {isArchived ? 'Przywróć konto' : 'Archiwizuj konto'}
        </h5>
        <p className="fs--1">
          Dane i historia konta będą nadal widoczne dla administrarów, ale
          użytkownik nie będzie miał możliwści logowania się do systemu.
        </p>
        <Button
          variant="falcon-warning"
          className="w-100"
          onClick={handleArchive}
        >
          {isArchived ? 'Przywróć konto' : 'Archiwizuj konto'}
        </Button>
        <div className="border-dashed border-bottom my-4" />
        <h5 className="mb-0">Usuń konto</h5>
        <p className="fs--1">Usunięcie konta jest nieodwracalne.</p>
        <Button
          variant="falcon-danger"
          className="w-100"
          onClick={handleDelete}
        >
          Usuń konto
        </Button>
      </Card.Body>
    </Card>
  );
};

DangerZone.propTypes = {
  user: userPropTypes
};

export default DangerZone;

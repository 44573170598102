import { toast } from 'react-toastify';

const copyToClipboard = content => {
  navigator.clipboard.writeText(content).then(
    () => {
      toast.success('Skopiowano do schowka', { theme: 'colored' });
    },
    err => {
      console.error('Error copying to clipboard:', err);
    }
  );
};

export default copyToClipboard;

import React from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import bgVideo from 'assets/video/romgos_intro_final.mp4';
import AuthSplitLayout from 'layouts/AuthSplitLayout';

const Logout = () => {
  return (
    <AuthSplitLayout bgProps={{ video: [bgVideo], position: '50% 20%' }}>
      <div className="text-center">
        <LogoutContent layout="split" titleTag="h3" />
      </div>
    </AuthSplitLayout>
  );
};

export default Logout;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnglesUp,
  faAnglesDown,
  faCircle
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export const priorityMap = {
  0: 'Wysoki',
  5: 'Średni',
  9: 'Niski'
};

const PriorityBadge = ({ priority = 'medium', small = false }) => {
  let variant, icon;
  switch (priority) {
    case '0':
      variant = 'danger';
      icon = faAnglesUp;
      break;
    case '5':
      variant = 'secondary';
      icon = faCircle;
      break;
    case '9':
      variant = 'info';
      icon = faAnglesDown;
      break;
    default:
      variant = 'secondary';
  }

  if (!priority) return null;

  let classes = `btn btn-sm btn-outline-${variant} mb-0`;
  if (small) classes = `badge badge-soft-${variant} lh-1`;

  return (
    <Form.Check.Label className={classes}>
      <FontAwesomeIcon icon={icon} size={small ? '2xs' : null} />
    </Form.Check.Label>
  );
};

PriorityBadge.propTypes = {
  priority: PropTypes.string,
  small: PropTypes.bool
};

export default PriorityBadge;

import changelog from 'data/changelog';

export const dashboardRoutes = {
  label: 'Panel główny',
  labelDisable: true,
  children: [
    {
      name: 'Panel główny',
      to: '/',
      exact: true,
      active: true,
      icon: 'chart-pie'
    }
  ]
};
export const appRoutes = {
  label: 'FSM',
  children: [
    {
      name: 'Kalendarz',
      icon: 'calendar-alt',
      to: '/calendar',
      active: true,
      authorizeRoles: ['admin', 'manager']
    },
    {
      name: 'Wiadomości',
      icon: 'comments',
      to: '/chat',
      active: false,
      authorizeRoles: ['admin', 'manager'],
      badge: {
        type: 'warning',
        text: 'wkrótce'
      }
    },
    {
      name: 'Zadania',
      icon: 'table',
      active: true,
      authorizeRoles: ['admin', 'manager'],
      children: [
        {
          name: 'Dodaj zadanie',
          to: '/create-task',
          active: true
        },
        {
          name: 'Lista zadań',
          to: '/task-list',
          active: true
        },
        {
          name: 'Lista lokalizacji',
          to: '/task-location-list',
          active: true
        },
        {
          name: 'Definicje checklisty',
          to: '/task-checklist-list',
          active: true
        },
        {
          name: 'Kategorie',
          to: '/task-category-list',
          active: true
        }
      ]
    },
    {
      name: 'Urządzenia',
      icon: 'truck-pickup',
      active: true,
      authorizeRoles: ['admin', 'manager'],
      children: [
        {
          name: 'Dodaj urządzenie',
          to: '/create-equipment',
          active: true
        },
        {
          name: 'Lista urządzeń',
          to: '/equipment-list',
          active: true
        },
        {
          name: 'Lista producentów',
          to: '/equipment-manufacturer-list',
          active: true
        },
        {
          name: 'Kategorie',
          to: '/equipment-category-list',
          active: true
        }
      ]
    },
    {
      name: 'Użytkownicy',
      icon: 'user',
      active: true,
      authorizeRoles: ['admin'],
      children: [
        {
          name: 'Lista użytkowników',
          to: '/user-list',
          active: true
        },
        {
          name: 'Dodaj użytkownika',
          to: '/create-user',
          active: true
        }
      ]
    }
    // TODO: Add reports routes
    // {
    //   name: 'Raporty',
    //   icon: 'chart-line',
    //   to: '/support-desk/reports',
    //   active: true
    // }
  ]
};

// HELPER ROUTES for development purposes
export const pagesRoutes = {
  label: 'Pozostałe strony',
  authorizeRoles: ['superadmin'],
  children: [
    {
      name: 'Logowanie',
      icon: 'lock',
      active: true,
      children: [
        {
          name: 'Logowanie',
          to: '/login',
          active: true
        },
        {
          name: 'Wylogowanie',
          to: '/logout',
          active: true
        },
        {
          name: 'Przypomnienie hasła',
          to: '/forgot-password',
          active: true
        },
        {
          name: 'Reset hasła',
          to: '/reset-password',
          active: true
        }
      ]
    }
  ]
};

export const helpPages = {
  label: 'Wsparcie techniczne',
  children: [
    {
      name: 'Pomoc',
      icon: 'question-circle',
      active: true,
      children: [
        {
          name: 'FAQ',
          to: '/help/faq-accordion',
          active: true
        },
        {
          name: 'Formularz kontaktowy',
          to: '/help/contact-form',
          active: true
        }
      ]
    },

    {
      name: 'Lista zmian',
      icon: 'code-branch',
      to: '/changelog',
      active: true,
      badge: {
        type: 'info',
        text: changelog[0].version
      }
    }
  ]
};

export default [dashboardRoutes, appRoutes, pagesRoutes, helpPages];

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';

const EquipmentDetails = ({ register, errors }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="equipmentName">
              <Form.Label>Nazwa urządzenia *</Form.Label>
              <Form.Control
                type="text"
                name="equipmentName"
                {...register('equipmentName')}
                placeholder="Nazwa urządzenia"
                isInvalid={!!errors.equipmentName}
              />
              <Form.Control.Feedback type="invalid">
                Pole jest wymagane
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="equipmentId">
              <Form.Label>Numer identyfikacyjny *</Form.Label>
              <Form.Control
                type="text"
                name="equipmentId"
                placeholder="Numer identyfikacyjny"
                {...register('equipmentId')}
                isInvalid={!!errors.equipmentCatalogNumber}
              />
              <Form.Control.Feedback type="invalid">
                Pole jest wymagane
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="equipmentCatalogNumber">
              <Form.Label>Numer katalogowy *</Form.Label>
              <Form.Control
                type="text"
                name="equipmentCatalogNumber"
                {...register('equipmentCatalogNumber')}
                placeholder="Numer katalogowy"
                isInvalid={!!errors.equipmentCatalogNumber}
              />
              <Form.Control.Feedback type="invalid">
                Pole jest wymagane
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="equipmentSerialNumber">
              <Form.Label>Numer seryjny *</Form.Label>
              <Form.Control
                type="text"
                name="equipmentSerialNumber"
                {...register('equipmentSerialNumber')}
                placeholder="Numer seryjny"
                isInvalid={!!errors.equipmentSerialNumber}
              />
              <Form.Control.Feedback type="invalid">
                Pole jest wymagane
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="equipmentInventoryNumber">
              <Form.Label>Numer inwentarzowy</Form.Label>
              <Form.Control
                type="text"
                name="equipmentInventoryNumber"
                placeholder="Numer inwentarzowy"
                {...register('equipmentInventoryNumber')}
              />
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="equipmentDescription">
              <Form.Label>Opis urządzenia</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                name="equipmentDescription"
                placeholder="Opis urządzenia"
                {...register('equipmentDescription')}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EquipmentDetails.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

export default EquipmentDetails;

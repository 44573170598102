import { Collapse, Form, Col, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { Controller, useForm } from 'react-hook-form';

export const UserFilters = ({ open, setQueryParams }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      manufacturer: []
    }
  });

  const onSubmit = data => {
    let queryParts = [];

    if (!data) {
      return;
    }

    if (data.name) {
      queryParts.push(`q=${encodeURIComponent(data.name)}`);
    }

    if (data.manufacturer?.length > 0) {
      data.manufacturer.map(manufacturer => {
        queryParts.push(`manufacturer[in]=${manufacturer.value}`);
      });
    }

    if (data.role?.length > 0) {
      data.role.map(role => {
        queryParts.push(`role[in]=${role.value}`);
      });
    }

    setQueryParams(queryParts?.join('&') || '');
  };

  return (
    <Collapse in={open}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group controlId="name" className="mb-3 d-flex flex-column">
              <Form.Label>Nazwa użytkownika</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nazwa użytkownika"
                isInvalid={!!errors.name}
                {...register('name')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} lg={4} xxl={3}>
            <Form.Group className="mb-3 d-flex flex-column">
              <Flex className="flex-between-center">
                <Form.Label>Uprawnienia użytkownika</Form.Label>
              </Flex>
              <Controller
                name="role"
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={true}
                    isMulti
                    clearable
                    options={[
                      {
                        value: 'service',
                        label: 'Serwisant'
                      },
                      {
                        value: 'manager',
                        label: 'Kierownik'
                      },
                      {
                        value: 'admin',
                        label: 'Administrator'
                      }
                    ]}
                    placeholder="Wybierz typ uprawnień..."
                    isInvalid={!!errors.type}
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Flex
              justifyContent="end"
              alignItems="end"
              style={{
                height: '100%'
              }}
            >
              <Button
                size="sm"
                variant="falcon-primary"
                type="submit"
                transform="shrink-3"
              >
                <span>Zastosuj filtry</span>
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Collapse>
  );
};

UserFilters.propTypes = {
  open: PropTypes.bool,
  setQueryParams: PropTypes.func
};

import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';

import { useParams } from 'react-router-dom';
import EquipmentDetailAside from './EquipmentDetailAside';
import EquipmentDetailContent from './EquipmentDetailContent';
import EquipmentDetailHeader from './EquipmentDetailHeader';

import axios from 'axios';
import { toast } from 'react-toastify';
import EquipmentLog from './EquipmentLog';

const EquipmentDetail = () => {
  const { equipmentId } = useParams();
  const [equipment, setEquipment] = useState(null);
  const [relatedTasks, setRelatedTasks] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment/${equipmentId}`
      );
      const equipment = response.data.equipment;
      setEquipment(equipment);
      setRelatedTasks(response.data.relatedTasks);
    } catch (error) {
      toast.error('Nie udało się pobrać danych o urządzeniu');
    }
  };

  useEffect(() => {
    if (equipmentId) {
      fetchData();
    }
  }, [equipmentId]);

  return (
    <>
      <EquipmentDetailHeader equipment={equipment} />
      {equipment ? (
        <Row className="g-3">
          <Col lg={8}>
            <EquipmentDetailContent equipment={equipment} />
            <EquipmentLog equipmentId={equipmentId} />
          </Col>
          <Col lg={4}>
            <EquipmentDetailAside
              equipment={equipment}
              relatedTasks={relatedTasks}
            />
          </Col>
        </Row>
      ) : (
        <Flex
          alignContent="center"
          justifyContent="center"
          style={{ marginTop: '20vh' }}
        >
          <Spinner animation="grow" />
        </Flex>
      )}
    </>
  );
};

export default EquipmentDetail;

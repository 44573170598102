import PropTypes from 'prop-types';

export const taskPropTypes = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.object,
  address: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
  endDate: PropTypes.string,
  endTime: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  ),
  equipmentId: PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  status: PropTypes.string,
  badge: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string
  })
});

export const getReactionTime = (createdAtStr, finishedAtStr) => {
  if (!createdAtStr || !finishedAtStr) return null;
  const createdAt = new Date(createdAtStr);
  const finishedAt = new Date(finishedAtStr);

  const reactionTimeMilliseconds = finishedAt - createdAt;
  const totalMinutes = Math.floor(reactionTimeMilliseconds / 1000 / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}h ${minutes}m`;
};

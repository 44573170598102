export default [
  {
    version: '1.0.0',
    publish: '2023-12-10',
    logs: {
      Dodano: [
        'Filtrowanie tabeli z wyposażeniem, użytkownikami, urządzeniami i listami kontrolnymi',
        'Funkcjonalność eksportu tabeli',
        'Obsługę pola awaria urządzenia',
        'Pole priorytetu w formularzu kontaktowym',
        'Modyfikację komponentu wyboru daty'
      ],
      Naprawiono: [
        'Błędy filtrowania, eksportu i inne mniejsze problemy związane z tabelami'
      ]
    }
  },
  {
    version: '1.0.0-beta.4',
    publish: '2023-10-26',
    logs: {
      Dodano: [
        'Możliwość wyboru wielu list kontrolnych dla jednego zadania',
        'Możliwość duplikowania list kontrolnych',
        'Możliwość zmiany kolejności pól w listach kontrolnych',
        'Pole typu tak/nie w polach niestandardowych w listach kontrolnych',
        'Wizualną reprezentację pól typu tak/nie',
        'Możliwość aktywacji zadania z ekranu edycji'
      ],
      Naprawiono: [
        'Błąd wyświetlania komunikatu o braku uprawnień użytkownika',
        'Brak parametru w komponencie resetowania hasła',
        'Nieprawiłowe elementy powiązanye z plikiem raportu'
      ]
    }
  },
  {
    version: '1.0.0-beta.3',
    publish: '2023-10-02',
    logs: {
      Dodano: [
        'Pola zgłaszającego w formularzu zadania',
        'Kolumna z autorem zadania w tabeli listy zadań',
        'Zaktualizowane domyślne ustawienia prywatności zadania',
        'Tłumaczenia dla etykiet tooltipów w menu'
      ],
      Naprawiono: [
        'Błąd wyświetlania komunikatów logowania',
        'Brak strony po kliknięciu linku do resetowania hasła',
        'Możliwości edycji danych użytkownika',
        'Połączenie formularza resetowania hasła profilu z API',
        'Podstawowe ustawienia dokumentu HTML',
        'Błąd wyświetlania powiadomień w menu podręcznym',
        'Błędy zmian masowych w tabeli list kontrolnych',
        'Wyświetlanie linków do raportów',
        'Błąd wyświetlania aktywności użytkownika',
        'Wyświetlanie zadań powiązanych w profilu użytkownika'
      ]
    },
    children:
      'W obecnej wersji aplikacji zoptymalizowano połączenia z serwerem oraz wprowadzono następujące zmiany:'
  },
  {
    version: '1.0.0-beta.2',
    publish: '2023-09-20',
    logs: {
      Dodano: [
        'Możliwość wysyłania zgłoszeń do pomocy technicznej',
        'Możliwość wyświetlania lokalizacji użytkownika na mapie',
        'Dodatkowa kolumna z nazwą pracownika w tabeli listy zadań',
        'Widok tabeli dla kolekcji list kontrolnych'
      ],
      Naprawiono: [
        'Błąd wyświetlania kalendarza w polu daty',
        'Błąd wyświetlania nazwy roli użytkownika',
        'Błąd wyświetlania liczby elementów na stronie w tabelach'
      ]
    }
  },
  {
    version: '1.0.0-beta.1',
    publish: '2023-09-17',
    logs: {
      Naprawiono: [
        'Błąd wyświetlania pól raportu w zadaniach zarchiwizowanych',
        'Brak tłumaczenia na polski nazw ról użytkowników',
        'Brak walidacji pola adresu użytkownika',
        'Błąd wyświetlania menu podręcznego na liście zadań',
        'Błąd filtrowania użytkowników po roli serwisowej',
        'Błąd wyświetlania dodatkowych opcji logowania',
        'Błąd wyświetlania opcji prywatności zadania',
        'Błąd wyświetlania adresu e-mail w linkach na stronie błędu'
      ]
    }
  },
  {
    version: '1.0.0-beta.0',
    publish: '31-08-2023',
    children: 'Publikacja wersji beta'
  },
  {
    version: '1.0.0-alpha.0',
    publish: '28-02-2023',
    children: 'Publikacja wersji alpha'
  }
];

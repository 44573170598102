import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

const ChecklistDetails = ({ register, errors }) => {
  return (
    <Card className="mb-3">
      <Card.Header as="h5">Szczegóły checklisty</Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="title">
              <Form.Label>Nazwa</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nazwa"
                isInvalid={!!errors.name}
                {...register('name', { required: true })}
              />
            </Form.Group>
          </Col>

          <Col md="12">
            <div className="border-dashed border-bottom"></div>
          </Col>

          <Col md="12">
            <Form.Group controlId="description">
              <Form.Label>Opis checklisty</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                name="description"
                {...register('description')}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ChecklistDetails.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};
export default ChecklistDetails;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { handleMultiple } from '../../../../helpers/bulkAction';

import PropTypes from 'prop-types';
import axios from 'axios';

import IconButton from 'components/common/IconButton';
import useTranslation from 'hooks/useTranslation';

const CategoryTableHeader = ({
  selectedRowIds,
  onCategoryAdded,
  setCategories,
  categories
}) => {
  const [showModal, setShowModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [selectedAction, setSelectedAction] = useState('');

  const handleApplyAction = () => {
    switch (selectedAction) {
      case 'delete':
        handleMultiple(
          selectedRowIds,
          'tasks/categories',
          categories,
          setCategories,
          'delete'
        );
        break;
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tasks/categories/add`,
        {
          name: categoryName
        }
      );
      setCategoryName('');
      handleCloseModal();
      if (onCategoryAdded) {
        onCategoryAdded(response.data);
      }
      toast.success('Dodano kategorię zadania', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  return (
    <>
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Kategorie</h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select
                size="sm"
                aria-label="Działania masowe"
                value={selectedAction}
                onChange={e => setSelectedAction(e.target.value)}
              >
                <option>Działania masowe</option>
                <option value="delete">Usuń</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
                onClick={handleApplyAction}
              >
                Zastosuj
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                className="ms-2"
                onClick={handleShowModal}
              >
                <span className="d-none d-sm-inline-block ms-1">
                  Dodaj kategorię
                </span>
              </IconButton>
            </div>
          )}
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dodaj kategorię</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="categoryName">
              <Form.Label>Nazwa kategorii</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź nazwę kategorii"
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CategoryTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  onCategoryAdded: PropTypes.func.isRequired,
  setCategories: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired
};

export default CategoryTableHeader;

import React from 'react';
import PropTypes from 'prop-types';
import SoftBadge from './SoftBadge';

export const USER_ROLES = {
  service: {
    label: 'Serwisant',
    color: 'secondary'
  },
  manager: {
    label: 'Kierownik',
    color: 'info'
  },
  admin: {
    label: 'Admin',
    color: 'danger'
  }
};

const RoleBadge = ({ role }) => {
  if (USER_ROLES[role] === undefined) {
    return null;
  }
  return (
    <SoftBadge pill bg={USER_ROLES[role].color}>
      {USER_ROLES[role].label}
    </SoftBadge>
  );
};

RoleBadge.propTypes = {
  role: PropTypes.string.isRequired
};

export default RoleBadge;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import changelog from '../../data/changelog';

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          <br className="d-sm-none" /> &copy; {new Date().getFullYear()}
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">{changelog[0].version}</p>
      </Col>
    </Row>
  </footer>
);

export default Footer;

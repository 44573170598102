// withRowLoading.js
import React, { useState } from 'react';

const withRowLoading = WrappedComponent => props => {
  const [loadingRows, setLoadingRows] = useState({});

  const setRowLoading = (id, isLoading) => {
    setLoadingRows(loadingRows => ({
      ...loadingRows,
      [id]: isLoading
    }));
  };

  return (
    <WrappedComponent
      {...props}
      loadingRows={loadingRows}
      setRowLoading={setRowLoading}
    />
  );
};

export default withRowLoading;

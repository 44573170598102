import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../context/auth/AuthContext';
import { toast } from 'react-toastify';
import LoadingSpinner from 'components/common/LoadingSpinner';

const PrivateRoute = ({ children, authorizeRoles }) => {
  const { isLoading, isAuthenticated } = useContext(AuthContext);
  const { user } = useContext(AuthContext);

  if (isLoading) {
    return <LoadingSpinner message="Wczytywanie danych..." />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (authorizeRoles && authorizeRoles.length > 0) {
    if (!['superadmin', ...authorizeRoles].includes(user.role)) {
      toast.error('Nie posiadasz odpowiednich uprawnień', {
        theme: 'colored'
      });
      return <Navigate to="/" />;
    }
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  authorizeRoles: PropTypes.array
};

export default PrivateRoute;

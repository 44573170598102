import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import useTranslation from 'hooks/useTranslation';

const PasswordResetForm = ({ hasLabel }) => {
  const { resetToken } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/password/reset/${resetToken}`,
        {
          password: formData.password,
          confirmPassword: formData.confirmPassword
        }
      );
      toast.success('Hasło ustawione poprawnie, możesz się teraz zalogować.');
      navigate('/login', { replace: true });
    } catch (err) {
      toast.error(useTranslation(err.response.data.message, 'pl'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Ustaw nowe hasło</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Ustaw nowe hasło' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Potwierdź hasło</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Potwierdź hasło' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword || isLoading}
      >
        Ustaw hasło
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
